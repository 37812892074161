let BaseTutorial, Tutorial;
BaseTutorial = require('./packages/intro.js');
import {substring} from "@uwebpro/substring/substring";
import {fireEvent} from "./fire-event";
import {tOptions} from "./tutorial/options";

window.tOptions = tOptions;
//Init IntroJs Obj
Tutorial = BaseTutorial.introJs();

function startTutorialFrom(startPoint) {
    Cookies.set('tutorial', startPoint);
    if (document.body.contains(document.getElementsByClassName('asset-result')[0])) {
        let el = document.getElementsByClassName('asset-result')[0];
        let el2 = document.getElementsByClassName('asset-result')[1];
        let wrapper = document.createElement('div');
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
        wrapper.appendChild(el2);
        $(wrapper).attr('id', 'firstTwoAssets');
    }
    Tutorial.setOptions(window.tOptions);
    Tutorial.goToStepNumber(startPoint).start();
}

window.addEventListener('DOMContentLoaded', () => {
    let url = window.location.href;
    if (url.indexOf('?tour=') !== -1) {
        startTutorialFrom(0);
    } else if (url.indexOf('&tour=') !== -1) {
        startTutorialFrom(0);
    }
});


if (Cookies.get('tutorial') === 'start') {
    startTutorialFrom(Cookies.get('tutorial'));

    window.widgetStartEvent = fireEvent('widgetStart', 'widgetStart', 'IntroJs Widget Start', {
        object: "introjs-tooltip",
        objectType: "startingPopup",
        eventInfo: {
            objectId: "step0",
            objectTitle: __('tours.initial.heading')
        }
    });
} else {
    if (Cookies.get('tutorial')) {
        if (Cookies.get('tutorial') !== 'ignored' && Cookies.get('tutorial') !== 'done' && Cookies.get('tutorial') !== 'undefined') {
            startTutorialFrom(Cookies.get('tutorial'));
        }
    }
}

//Start Tour Body
$(document.body).on('click', '#startTourBtn', function (e) {
    e.preventDefault();
    window.StartTourEvent = fireEvent('StartTour', 'startTour', 'New Features Tour', {
        eventInfo: {
            location: 'body',
            page: window.location.pathname
        }
    });
    Cookies.set('tutorial', 'start');
    window.location.href = 'https://' + window.location.hostname + '/' + window.current_locale + '/search?q=';
});


//Before Card Change
Tutorial.onbeforechange(() => {
    let element = $(Tutorial._options.steps[Tutorial._currentStep].element);
    if (element.attr('id') === 'step2') {
        document.body.scrollTop = element.offsetTop - document.body.offsetTop;
    }
    // if(element.attr('id') === 'step6'){
    //     window.widgetLoadedEvent = fireEvent('widgetLoaded', 'widgetAction', 'IntroJs Widget Action', {
    //         objectType: "tourDtopNav",
    //         eventInfo: {
    //             action: "end tour",
    //             objectId: 'step6',
    //             page: __('tours.tour.steps.step6.title')
    //         }
    //     });
    // }
});

//On Card Change
Tutorial.onchange(targetElement => {
    let checkboxes;
    if ($(targetElement).attr('id') === 'firstTwoAssets') {
        checkboxes = $(targetElement).find(':checkbox');
        checkboxes.each(function () {
            $(this).prop('checked', true);
        });

        checkCheckedCount();
        checkAssets();
    } else {
        checkboxes = $('#firstTwoAssets').find(':checkbox');
        checkboxes.each(function () {
            $(this).prop('checked', false);
        });

        checkCheckedCount();
        checkAssets();
    }
    if ($(targetElement).attr('id') === 'step2') {
        Tutorial.setOption('scrollToElement', false);
        window.scrollTo(0, 0);
        document.body.scrollTop = 0; // For Safari
    } else {
        Tutorial.setOption('scrollToElement', true);
    }
});

// After Card Change
Tutorial.onafterchange(targetElement => {
    let direction;
    if (Tutorial._direction === 'backward') {
        direction = 'previous';
    } else {
        direction = 'continue';
    }
    logTutorialEvent(targetElement, direction);
    if ($(targetElement).attr('id') === 'step6') {
        $('.introjs-donebutton').insertAfter('.introjs-nextbutton');
    }
});

//Complete Tour
Tutorial.oncomplete(() => {
    window.widgetLoadedEvent = fireEvent('widgetLoaded', 'widgetAction', 'IntroJs Widget Action', {
        objectType: "tourDtopNav",
        eventInfo: {
            action: "end tour",
            objectId: 'step6',
            page: __('tours.tour.steps.step6.title')
        }
    });
    Cookies.set('tutorial', 'ignored');
});

//Exit Tour
Tutorial.onexit(() => {
    Cookies.set('tutorial', 'ignored');
});


function logTutorialEvent(element, direction = 'continue') {
    let step = Tutorial._options.steps[Tutorial._currentStep];
    if (!step) return;

    window.widgetActionEvent = fireEvent('widgetAction', 'widgetAction', 'IntroJs Widget Action', {
        object: "introjs-tooltip",
        objectType: "tourDtopNav",
        eventInfo: {
            action: direction,
            objectId: `step${Tutorial._currentStep}`,//step?.element,
            page: substring(step?.intro, '<h2>', '</h2>'),
        }
    });


    window.pageLoadedEvent = fireEvent('pageView', 'pageView', 'Introjs Page Viewed', {
        object: "introjs-tooltip",
        eventType: 'userEvent',
        objectType: `step${Tutorial._currentStep}`,//step?.element,
        eventInfo: {
            action: 'pageView',
            triggeredBy: direction,
            page: substring(step?.intro, '<h2>', '</h2>'),
        }
    });

    // window.widgetLoadedEvent = fireEvent('widgetLoaded', 'widgetLoaded', 'Introjs Widget Loaded', {
    //     object: "introjs-tooltip",
    //     eventType: 'userEvent',
    //     objectType: step?.element,
    //     eventInfo: {
    //         action: 'widgetLoaded',
    //         triggeredBy: direction,
    //         page: substring(step?.intro, '<h2>', '</h2>'),
    //     }
    // });
}