if($(window).width() <= '768'){
    $('#filterCont').animate({height: 'toggle'}, 0 );
}

$(document).on("click","#mobFilterTrigger",function() {
    $('#filterCont').animate({height: 'toggle'}, 500 );
});

$(document).on('contextmenu', 'img', function() {
    return false;
});
