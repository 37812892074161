import {fireEvent} from "./fire-event";
import Vue from 'vue';
import DownloadRestrictions from "./components/DownloadRestrictions";

let zipId, form, updateSelection, checkDownload, checkedCount, downloads;

function doLogin() {
    window.header.$data.openLogin = true;
}

window.addEventListener("beforeunload", function (e) {
    if (getCheckedCount() > 0) {
        const confirmationMessage = __('multidownload.popups.leaving.title');

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    }
});

function checkZippedFiles($download = 1) {
    let swalTitle = $download === 1 ? __('multidownload.popups.zipping.title') : __('multidownload.popups.zipping.title2');
    Swal.fire({
        title: swalTitle,
        text: __('multidownload.popups.zipping.para'),
        customClass: {
            container: 'sweetWalkOverlay',
            popup: 'sweetWalkPopup',
            header: 'sweetWalkHeader',
            title: 'sweetWalkTitle',
            closeButton: 'sweetWalkCloseBTN',
            icon: 'sweetWalkIcon',
            image: 'sweetWalkImage',
            content: 'sweetWalkContent',
            input: 'sweetWalkInput',
            actions: 'sweetWalkActions',
            // confirmButton: 'sweetLoader',
            cancelButton: 'sweetWalkCancelBTN',
            footer: 'sweetWalkFooter'
        },
        allowOutsideClick: false,
        onBeforeOpen: () => {
            Swal.showLoading();
        }
    });
    checkDownload = setInterval(async () => {
        window.axios.get('/api/' + window.current_locale + '/multi-download/check/' + zipId + '?count=' + $download).then(async response => {
            if (response && response.data) {
                downloads = response.data;
                if (downloads > 1) {
                    $('#download').append(`<iframe hidden src="/api/${window.current_locale}/multi-download/${zipId}/download?count=${$download}"></iframe>`);
                    clearInterval(checkDownload);
                    checkZippedFiles(2);
                } else {
                    let eventData = await getEventData(zipId);
                    window.downloadEvent = fireEvent('download', 'download', 'Download Assets', {
                        eventInfo: {
                            location: window.location.pathname,
                            assetSelected: getCheckedCount(),
                            downloadType: getCheckedCount() === 18 ? "select all" : "multiselect",
                            brand: eventData.brands,
                            assetType: eventData.type,
                        }
                    });
                    clearInterval(checkDownload);

                    let html = '' +
                            '<p>' + __('multidownload.popups.started.para')
                            + '<iframe hidden src="/api/' + window.current_locale + '/multi-download/' + zipId + '/download?count=' + $download + '"></iframe>'
                            + ' '
                            + '<a href="/api/' + window.current_locale + '/multi-download/' + zipId + '/download?count=' + $download + '">' + __('multidownload.popups.started.a')
                            + '</a></p>';


                    Swal.fire({
                        title: __('multidownload.popups.started.title'),
                        html: html,
                        showConfirmButton: true,
                        showCloseButton: false,
                        customClass: SWAL_CLASSLIST,
                    });
                    $('#multiDownload').trigger('reset');
                    checkCheckedCount();
                    $('.listingMultiCheckbox').each(function () {
                        $(this).prop('checked', false);
                        displayAssetsAsSelected($(this));
                    });
                }
            }
        }).catch(function (error) {
            clearInterval(checkDownload);
            Swal.fire({
                title: 'Oops',
                text: error,
                customClass: SWAL_CLASSLIST,
            });
        });
    }, 2500);
}

async function updateDownload(updateForm) {
    await Swal.fire({
        title: __('multidownload.popups.download.title'),
        html: updateForm,
        focusConfirm: true,
        confirmButtonText: __('multidownload.popups.download.download'),
        customClass: {
            container: 'sweetWalkOverlay',
            popup: 'sweetWalkPopup',
            header: 'sweetWalkHeader',
            title: 'sweetWalkTitle',
            closeButton: 'sweetWalkCloseBTN',
            icon: 'sweetWalkIcon',
            image: 'sweetWalkImage',
            content: 'sweetWalkContent',
            input: 'sweetWalkInput',
            actions: 'sweetWalkActions',
            confirmButton: 'multiDLSubmit',
            cancelButton: 'sweetWalkCancelBTN',
            footer: 'sweetWalkFooter'
        }
    }).then((result) => {
        let values = $('#selectImageTypes').serialize();
        if (result.value) {
            window.axios.post('/api/' + window.current_locale + '/multi-download/update', values).then(function (response) {
                if (response) {
                    return true;
                }
            });
        } else {
            throw new Error('Cancelled');
        }
    });
}

let mountRestrictions = (id) => {
    let ComponentClass = Vue.extend(DownloadRestrictions);
    let vueInstance = new ComponentClass({
        propsData: {
            id: id
        }
    });
    vueInstance.$mount();
    document.querySelector('#downloadRestrictions').appendChild(vueInstance.$el);
    return vueInstance;
};

$(document.body).on('submit', '#multiDownload', function (e) {
    e.preventDefault();
    window.axios.get('/api/auth').then(response => {
        if (response.data || getCheckedCount() === 1) {
            form = $('#multiDownload');
            form.append({file_name: 'Wella Download'});
            window.axios.post(`/api/${window.current_locale}/multi-download/request`, form.serialize()).then(response => {
                let instance = mountRestrictions(response.data.download);
                instance.$on('cancel', () => {
                    instance.$destroy();
                });
                instance.$on('accept', () => {
                    instance.$destroy();
                    if (response) {
                        if (response.data.download) {
                            zipId = response.data.download;
                            updateSelection = '<form id="selectImageTypes" class="downloadAttrForm">' +
                                '<input type="hidden" name="id" value="' + zipId + '"/>';
                            if (response.data.moreInfo) {
                                updateSelection += '<h4> ' + __('multidownload.popups.download.step1') + ' </h4>' +
                                    '<label class="multiDLCheckLabel">' +
                                    '<input class="multiDLRadioInput" checked type="checkbox" name="chosen_type[]" value="web" /> ' + __('multidownload.popups.download.checkWeb') + '</label>' +
                                    '<label class="multiDLCheckLabel">' +
                                    '<input class="multiDLRadioInput" checked type="checkbox" name="chosen_type[]" value="print" />' + __('multidownload.popups.download.checkPrint') + '</label>' + '<br/>';
                            }
                            updateSelection += '<h4>' + __('multidownload.popups.download.step2') + '</h4>' +
                                '<p>' + __('multidownload.popups.download.note') + '</p>' +
                                '<div class="publicAdmin"><div class="boxBody"><input class="multiDLNameField" type="text" name="file_name" placeholder="' + __('multidownload.popups.download.placeholder') + '" value="" /></div></div>' +
                                '</form>';
                            updateDownload(updateSelection).then(() => {
                                checkZippedFiles();
                            }).catch((e) => {

                            });
                        } else {
                            checkZippedFiles();
                        }
                    }
                });
            });
        } else {
            doLogin();
        }
    }).catch(error => {
        doLogin();
    });
});

function displayAssetsAsSelected(element) {
    if ($(element).is(':checked')) {
        $(element).parent().parent().addClass('assetSelected');
        $(element).addClass("multiHideChecked");
    } else {
        $(element).parent().parent().removeClass('assetSelected');
        $(element).removeClass("multiHideChecked");
    }
}


function getCheckedCount() {
    checkedCount = 0;
    $('.listingMultiCheckbox').each(function () {
        if ($(this).is(":checked")) {
            checkedCount++;
        }
    });
    return checkedCount;
}

window.checkCheckedCount = function checkCheckedCount() {
    checkedCount = 0;
    $('.listingMultiCheckbox').each(function () {
        if ($(this).is(":checked")) {
            checkedCount++;
        }
    });
    if (checkedCount > 0) {

        $('#downloadAssetsBtn').removeAttr('hidden');
    } else {
        $('#downloadAssetsBtn').attr('hidden', 'hidden');
    }
    $('#assetsSelected').html('(' + checkedCount + ')');
};

$(document.body).on('change', '#selectAllAssets', function () {
    let checkboxes = $(this).closest('form').find(':checkbox');
    checkboxes.prop('checked', $(this).is(':checked'));
    checkCheckedCount();
    let text = $(this).is(':checked') ? __('multidownload.labels.unselect') : __('multidownload.labels.select');
    $('#selectAllAssetsLabel').text(text);
    let element = $('.listingMultiCheckbox');
    displayAssetsAsSelected(element);

});

$(document.body).on('change', '.listingMultiCheckbox', function () {
    checkCheckedCount();
    displayAssetsAsSelected(this);
});

window.checkAssets = function checkAssets() {
    $('.listingMultiCheckbox').each(function () {
        displayAssetsAsSelected($(this));
    });
};

$(document).ready(function () {
    checkCheckedCount();
    checkAssets();
});

const getEventData = async (id) => {
    return await axios.get(`/api/multi-download/event-data/${window.current_locale}/${id}`).then(response => {
        return response.data
    });
};
