var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wmsa"},[_c('section',[(_vm.alerts)?_c('alerts',{attrs:{"alerts":_vm.alerts}}):_vm._e(),_vm._v(" "),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"publicAdmin"},[_c('div',{staticClass:"boxTitle"},[_c('h2',[_vm._v(_vm._s(_vm.$lang.trans('general.your_password')))])]),_vm._v(" "),_c('div',{staticClass:"boxBody"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('p',[_vm._v(_vm._s(_vm.$lang.trans('general.change_password_for_you'))+".")])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('label',{staticClass:"visuallyhidden",attrs:{"for":"email"}},[_vm._v("\n                                            "+_vm._s(_vm.$lang.trans('general.email_address'))+"\n                                        ")]),_vm._v(" "),_c('validation-provider',{attrs:{"rules":{required: true, email: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",attrs:{"readonly":"","type":"email","id":"email","placeholder":_vm.$lang.trans('general.email_address'),"required":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_vm._v(" "),(!_vm.sent)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"col-sm-6"},[_c('label',{staticClass:"visuallyhidden",attrs:{"for":"password"}},[_vm._v(_vm._s(_vm.$lang.trans('general.your_new_password')))]),_vm._v(" "),_c('validation-provider',{attrs:{"rules":{ required: true, confirmed:'password_confirmation' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"id":"password","type":"password","placeholder":_vm.$lang.trans('general.your_new_password'),"autocomplete":"off","required":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_vm._v(" "),(!_vm.sent)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0])),_c('br')]):_vm._e()]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-sm-offset-6"},[_c('label',{staticClass:"visuallyhidden",attrs:{"for":"password_confirmation"}},[_vm._v(_vm._s(_vm.$lang.trans('general.confirm_password')))]),_vm._v(" "),_c('validation-provider',{attrs:{"rules":{required: true},"vid":'password_confirmation'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirmation),expression:"password_confirmation"}],attrs:{"id":"password_confirmation","type":"password","placeholder":_vm.$lang.trans('general.confirm_password'),"autocomplete":"off","required":""},domProps:{"value":(_vm.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirmation=$event.target.value}}}),_vm._v(" "),(!_vm.sent)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0])),_c('br')]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('p',[_c('button',{staticClass:"btnNutural",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._v(_vm._s(_vm.$lang.trans('general.password_reset'))+"\n                                            ")])])],1)])])])])])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }