;
var formValidation;
(function() {

    if(!$('.validate-form').length) return;

    // Ensure we focus on the first invalid tab
    window.Parsley.on('field:error', function() {
        if(!hasTabs) return;
        // find the first one
        err = $(".form-error-list").first();
        tab = err.closest('.tab-pane');
        $('a[href="#'+tab.attr("id")+'"]').click();
    });

    options = {
        errorClass: 'form-control-error',
        errorsWrapper: '<div class="form-error-list"></div>',
        errorTemplate: '<p></p>'
    };
    formValidation = $('.validate-form').parsley(options)
})();