var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"publicAdmin"},[_c('div',{staticClass:"boxTitle"},[_c('h2',[_vm._v(_vm._s(_vm.trans('your_password')))])]),_vm._v(" "),(_vm.alerts)?_c('div',{staticClass:"row",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"col-sm-12"},[(_vm.alerts.errors)?_c('div',{staticClass:"alert alert-danger"},_vm._l((_vm.alerts.errors),function(error){return _c('p',[_vm._v(_vm._s(error))])}),0):_vm._e(),_vm._v(" "),(_vm.alerts.success)?_c('div',{staticClass:"alert alert-success"},_vm._l((_vm.alerts.success),function(error){return _c('p',[_vm._v(_vm._s(error))])}),0):_vm._e()])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"boxBody"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('p',[_vm._v(_vm._s(_vm.trans('change_password_for_you'))+".")])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('h4',[_vm._v(_vm._s(_vm.trans('update_your_password'))+":")])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('label',{staticClass:"visuallyhidden",attrs:{"for":"current_password"}},[_vm._v(_vm._s(_vm.trans('your_current_password')))]),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.trans('your_current_password'),"rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.current_password),expression:"current_password"}],attrs:{"id":"current_password","type":"password","placeholder":_vm.trans('your_current_password'),"autocomplete":"off","required":""},domProps:{"value":(_vm.current_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.current_password=$event.target.value}}}),_vm._v(" "),_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('label',{staticClass:"visuallyhidden",attrs:{"for":"password"}},[_vm._v(_vm._s(_vm.trans('your_new_password')))]),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.trans('your_new_password'),"rules":{required: true, confirmed:'confirmation'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"id":"password","type":"password","placeholder":_vm.trans('your_new_password'),"autocomplete":"off","required":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_vm._v(" "),_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"col-sm-6"},[_c('label',{staticClass:"visuallyhidden",attrs:{"for":"password_confirmation"}},[_vm._v(_vm._s(_vm.trans('confirm_password')))]),_vm._v(" "),_c('validation-provider',{attrs:{"name":_vm.trans('confirm_password'),"vid":"confirmation","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirmation),expression:"password_confirmation"}],attrs:{"id":"password_confirmation","type":"password","placeholder":_vm.trans('confirm_password'),"autocomplete":"off","required":""},domProps:{"value":(_vm.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirmation=$event.target.value}}}),_vm._v(" "),_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"publicAdmin"},[_c('div',{staticClass:"boxBodyWht"},[_c('div',{staticClass:"buttonWrapper"},[_c('button',{staticClass:"btnPositive",attrs:{"id":""},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._v(_vm._s(_vm.trans('save_and_update'))+"\n              ")])])])])])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }