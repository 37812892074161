// var hasStorage, hasTabs, hasErrors;
// !function () {
//     hasStorage = !1;
//     try {
//         localStorage.setItem("test", "test"), localStorage.removeItem("test"), hasStorage = !0
//     } catch (t) {
//     }
//     $('a[data-toggle="tab"]').on("shown.bs.tab", function (t) {
//         hasStorage && (el = $(t.target), localStorage.setItem("last_tab", el.attr("href")))
//     }), hasTabs = $(".nav-tabs").size(), hasErrors = $(".form-error").size(), hasTabs || localStorage.setItem("last_tab", null), lastTab = localStorage.getItem("last_tab"), !hasErrors && hasTabs && lastTab ? $('a[href="' + lastTab + '"]').click() : hasErrors && (first = $(".form-error").first(), tab = first.closest(".tab-pane"), $('a[href="#' + tab.attr("id") + '"]').click())
// }(), setTimeout(function () {
//     $(".alert-success.alert-dismissible").alert("close")
// }, 3e3);
// var formValidation;
// !function () {
//     $(".validate-form").length && (window.Parsley.on("field:error", function () {
//         hasTabs && (err = $(".form-error-list").first(), tab = err.closest(".tab-pane"), $('a[href="#' + tab.attr("id") + '"]').click())
//     }), options = {
//         errorClass: "form-control-error",
//         errorsWrapper: '<div class="form-error-list"></div>',
//         errorTemplate: "<p></p>"
//     }, formValidation = $(".validate-form").parsley(options))
// }(), $(document).on("change", "#localePicker", function () {
//     window.location = "/" + $(this).val() + "/my-admin"
// }), function () {
//     toggleGroup = function (t) {
//         t.preventDefault();
//         var a = $(this).closest(".toggle-group");
//         anySelected(a) ? cValue = !1 : cValue = !0, a.find("input:checkbox").prop("checked", cValue)
//     }, anySelected = function (t) {
//         return t.find("input:checked").length
//     }, $("button.toggle-all").on("click", toggleGroup)
// }();

if (document.body.contains(document.querySelector('#open-category-row'))) {
    categoryToggle = function () {
        if ($(".category-toggle").hasClass('showing')) {
            $(".category-toggle").removeClass('showing');
            showing = 0;
        } else {
            $(".category-toggle").addClass('showing');
            showing = 1;
        }
        $('.category-container').slideToggle();
    }
    categoryCountSelected = function () {
        $(".category-count").text($("input[name='category[]']:checked").length);
    }
    categoryLoad = function () {
        showing = localStorage.getItem('admin.assets.category.filer');
        if (showing == 1) {
            $(".category-toggle").addClass('showing');
            $('.category-container').show();
        }
    }
    $(document).on("click", ".category-toggle", categoryToggle);
    $(document).on("change", "input[name='category[]']", categoryCountSelected);
    categoryLoad();

    $(document).on('click', '#open-category-row', () => {
        $('#category-row').toggleClass('show');
    });
}