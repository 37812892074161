<template>
    <div class="wmsa">
        <section>
            <div class="col-sm-6 col-sm-offset-3">
                <alerts v-if="alerts" :alerts="alerts"></alerts>
                <div class="publicAdmin">
                    <div class="boxTitle">
                        <h2>{{ $lang.trans('general.email_reset_link') }}</h2>
                    </div>
                    <div class="boxBody txtOverrideCent">
                        <p>{{ $lang.trans('general.email_reset_link_intructions') }}</p>
                        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(submit)">
                                <label for="email">{{ $lang.trans('general.email') }}</label>
                                <validation-provider rules="email" v-slot="{errors}">
                                    <input type="email" name="email" id="email" v-model="email"
                                           :placeholder="$lang.trans('general.email_address')" required>
                                    <span v-if="!sent" class="error">{{ errors[0] }}</span>
                                </validation-provider>
                                <br><br>
                                <button type="button" class="btnNutural"  @click.prevent="submit">{{ $lang.trans('general.password_reset') }}</button>
                            </form>
                        </ValidationObserver>
                        <p v-html="$lang.trans('general.password_reset_text')"></p>
                        <strong>{{ $lang.trans('general.password_reset_text_2') }} </strong>
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>

<script>
    import Alerts from "./Alerts";

    export default {
        name: "SendPasswordReset",
        components: {Alerts},
        props: {
            locale: {
                required: true,
            }
        },
        data: () => {
            return {
                email: null,
                alerts: null,
                sent: false,
            }
        },
        methods: {
            submit() {
                this.sent = false;
                this.$refs.observer.validate().then(success => {
                    if (!success) {
                        this.alerts = {
                            errors: [this.trans('please_review_errors')],
                        };
                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0;
                    }
                    else{
                        let data = window.makeFormData({email: this.email});
                        axios.post(`/api/password/generate/token/${this.locale}`, data, {
                            headers: {'Content-Type': 'multipart/form-data'}
                        }).then(response => {
                            document.body.scrollTop = 0; // For Safari
                            document.documentElement.scrollTop = 0;
                            this.email = '';
                            this.sent = true;
                            this.alerts = response.data;
                        });
                    }
                });
            },
            trans(key, replacements = {}) {
                return this.$lang.trans(`general.${key}`, replacements);
            },
        }
    }
</script>