<template>
    <div class="wmsa">
        <section>
            <alerts v-if="alerts" :alerts="alerts"></alerts>
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="publicAdmin">
                                <div class="boxTitle">
                                    <h2>{{ $lang.trans('general.your_password') }}</h2>
                                </div>

                                <div class="boxBody">

                                    <div class="row">
                                        <div class="col-sm-12">
                                            <p>{{ $lang.trans('general.change_password_for_you') }}.</p>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label class="visuallyhidden" for="email">
                                                {{$lang.trans('general.email_address')}}
                                            </label>
                                            <validation-provider :rules="{required: true, email: true}"
                                                                 v-slot="{ errors }">
                                                <input readonly class="form-control" type="email" id="email"
                                                       v-model="email"
                                                       :placeholder="$lang.trans('general.email_address')" required>
                                                <span v-if="!sent" class="error">{{ errors[0] }}</span>
                                            </validation-provider>
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="password"
                                                   class="visuallyhidden">{{ $lang.trans('general.your_new_password')
                                                }}</label>
                                            <validation-provider
                                                    :rules="{ required: true, confirmed:'password_confirmation' }"
                                                    v-slot="{ errors }">
                                                <input id="password" type="password" v-model="password"
                                                       :placeholder="$lang.trans('general.your_new_password')"
                                                       autocomplete="off"
                                                       required>
                                                <span v-if="!sent" class="error">{{ errors[0] }}<br></span>
                                            </validation-provider>
                                        </div>
                                    </div>

                                    <div class="row">

                                        <div class="col-sm-6 col-sm-offset-6">
                                            <label for="password_confirmation"
                                                   class="visuallyhidden">{{ $lang.trans('general.confirm_password')
                                                }}</label>
                                            <validation-provider :rules="{required: true}" v-slot="{ errors }"
                                                                 :vid="'password_confirmation'">
                                                <input id="password_confirmation"
                                                       type="password"
                                                       v-model="password_confirmation"
                                                       :placeholder="$lang.trans('general.confirm_password')"
                                                       autocomplete="off"
                                                       required>
                                                <span v-if="!sent" class="error">{{ errors[0] }}<br></span>
                                            </validation-provider>

                                            <p>
                                                <button type="button" class="btnNutural" @click.prevent="submit">{{
                                                    $lang.trans('general.password_reset') }}
                                                </button>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </section>
    </div>
</template>

<script>
    import Alerts from "./Alerts";

    export default {
        name: "ResetPassword",
        components: {Alerts},
        props: {
            token: {
                required: true,
                type: String,
            },
            locale: {
                required: true,
                type: String
            },
            email: {
                required: true,
                type: String,
            },
        },
        data: () => {
            return {
                alerts: null,
                password: null,
                password_confirmation: null,
                sent: false,
            }

        },
        methods: {
            submit() {
                this.sent = false;
                this.$refs.observer.validate().then(success => {
                    if (!success) {
                        this.alerts = {
                            errors: [this.trans('please_review_errors')],
                        };
                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0;
                    } else {
                        axios.post(`/sso/update-password`, window.makeFormData({
                            password: this.password,
                            password_confirmation: this.password_confirmation,
                            email: this.email,
                            token: this.token
                        })).then(response => {
                            this.alerts = {success: []};
                            this.sent = true;
                            this.alerts.success.push(this.$lang.trans(response.data));
                            this.errors = [];
                            let customer = {
                                email: this.email,
                                password: this.password,
                                remember: 0,
                            };
                            axios.post('/api/login', window.makeFormData(customer)).then(response => {
                                if (response.data.id) {
                                    this.password = '';
                                    this.password_confirmation = '';
                                    this.$emit('triggerlogin', response.data);
                                } else {
                                    this.alerts.push(response.data);
                                }
                            }).catch(err => {
                                this.errors.push(err);
                            });
                        });
                    }
                });
            },
            trans(key, replacements = {}) {
                return this.$lang.trans(`general.${key}`, replacements);
            },
        }
    }
</script>