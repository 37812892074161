<template>
    <div class="form-group">
        <label :for="label.toLowerCase()">{{ label }}</label>

        <input type="checkbox" :id="ident" :value="true" v-model="source_code">
        <label :for="ident">Show Source Code</label>

        <textarea class="form-control" :class="{source_code: source_code}"
                  :hidden="!source_code" :name="name">{{ fieldData }}</textarea>
        <ckeditor v-if="!source_code" :editor="editor" v-model="fieldData"
                  :config="editorConfig"></ckeditor>
    </div>
</template>

<script>
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

    export default {
        name: "text-area",
        props: {
            label: {
                required: true,
                type: String
            },
            name: {
                required: true,
                type: String,
            },
            content: {
                required: false,
                type: String
            },
        },
        mounted() {
            if (this.content) {
                this.fieldData = this.content;
                this.source_code = true;

                this.sleep(1000).then(() => {
                    this.source_code = false;
                });
            }
            this.ident = this.makeid(20);
        },
        data: () => {
            return {
                fieldData: null,
                source_code: false,
                ident: null,

                editor: ClassicEditor,
                editorConfig: {},
            }
        },
        methods: {
            makeid(length) {
                var result = '';
                var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for (var i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            },
            sleep(time) {
                return new Promise((resolve) => setTimeout(resolve, time));
            }
        }
    }
</script>

<style scoped>
    input[type='checkbox'] {
        margin-left: 20px;
    }

    textarea {
        border-radius: 0;
        min-height: 100px;
    }
</style>