$(window).scroll(function () {
    var sticky = $('.sticky'),
        winWidth = $(window).width(),
        scroll = $(window).scrollTop();
    if (scroll >= 34) {
        sticky.addClass('fixed');
    } else {
        sticky.removeClass('fixed');
    }

    if (scroll >= 34 && winWidth >= 769) {
        $('#mainLogo').stop().animate({marginTop: "-20px", marginBottom: "10px"}, 100);
    } else if (scroll >= 34 && winWidth >= 769) {
        $('#mainLogo').stop().animate({marginTop: "-20px", marginBottom: "10px"}, 100);
    } else {
        $('#mainLogo').stop().animate({marginTop: "0", marginBottom: "15px"}, 100);
    }

    if (winWidth <= 736) {
        // console.log('Window is smaller than 736px and is a small screen');
        if (scroll >= 200) {
            $("#loginRegister").removeClass("in");
        }
        if (scroll < 200) {
            //$("#loginRegister").removeClass("in");
        }

    } else {
        // console.log('Window is larger than 736px and is a large screen');
        if (scroll >= 50) {
            $("#loginRegister").removeClass("in");
        }
        if (scroll < 50) {
            //$("#loginRegister").removeClass("in");
        }

    }

});