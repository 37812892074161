;(function() {

    /**
     * CMS Homepage slider image deleting
     */
    function clickCmsSliderDeleteButton(ev) {
        el = $(ev.relatedTarget);
        $("#slider-image-name").text(el.data("label"));
        $("#delete-slider-image-form").attr("action",el.data("action"));
    }

    function deleteSliderImage(ev) {
        ev.preventDefault();
        $.ajax({
            type: "DELETE",
            url: $('#delete-slider-image-form').attr("action"),
            data: $('#delete-slider-image-form').serialize(),
            success: function(resp){
                $('.hide'+resp.id).css("display", "none");
                $('#deleteSliderImageModal').modal('hide');
                console.log(resp, resp.id);
            },
            error: function(){},
            dataType: 'json'
        });
    }

    $('#deleteSliderImageModal').on('show.bs.modal', clickCmsSliderDeleteButton);
    $('#delete-slider-image-form').on('submit', deleteSliderImage);

})();