let Cookie = require('js-cookie');
$(document.body).on('click', '.editAssets', function (e) {
    e.preventDefault();
    Swal.showLoading();
    window.assets = [];
    $('.assetlist-checkbox').each(function () {
        if ($(this).prop('checked')) {
            window.assets.push($(this).val());
            Cookie.set('last_selected', JSON.stringify(window.assets));
        }
    });
    if (window.assets.length === 1) {
        window.location.href = $('.' + window.assets[0] + '-link').attr('href');
    }
});

$(document.body).on('click', '.deleteAssets', function (e) {
    e.preventDefault();
    Swal.showLoading();
    window.deleteList = [];
    window.propList = [];
    $('.assetlist-checkbox').each(function () {
        if ($(this).prop('checked')) {
            window.deleteList.push($(this).val());
        }
    });
});

window.hasBeenDeleted = async () => {
    Swal.fire({
        title: 'Asset Successfully Deleted',
        customClass: window.SWAL_CLASSLIST,
    });
    $('.assetlist-checkbox').each(function () {
        if ($(this).prop('checked')) {
            $(this).parent().parent().attr('hidden', 'hidden');
            $(this).remove();
        }
    })

    return null;
};

if (Cookie.get('last_selected')) {
    $('.assetlist-checkbox').each(function () {
        if (JSON.parse(Cookie.get('last_selected')).includes($(this).val())) {
            $(this).prop('checked', 'checked')
            $('.btn-assetlist-disablegroup').attr('disabled', false);
        }
    });
    Cookie.set('last_selected', null);
}