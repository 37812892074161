import Vue from 'vue';
import GbMenu from "./components/GB/GbMenu";
import $lang from './translations';
import {library} from '@fortawesome/fontawesome-svg-core'
import {faChevronDown, faChevronUp, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faPlayCircle} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {localize, ValidationObserver, ValidationProvider} from 'vee-validate/dist/vee-validate.full';
import en from 'vee-validate/dist/locale/en.json';
import nl from 'vee-validate/dist/locale/nl.json';
import it from 'vee-validate/dist/locale/it.json';
import de from 'vee-validate/dist/locale/de.json';
import nn_NO from 'vee-validate/dist/locale/nn_NO.json';
import pt_PT from 'vee-validate/dist/locale/pt_PT.json';
import sv from 'vee-validate/dist/locale/sv.json';
import da from 'vee-validate/dist/locale/da.json';
import el from 'vee-validate/dist/locale/el.json';
import fr from 'vee-validate/dist/locale/fr.json';
import ru from 'vee-validate/dist/locale/ru.json';
import fi from 'vee-validate/dist/locale/fi.json';
import ja from 'vee-validate/dist/locale/ja.json';
import es from 'vee-validate/dist/locale/es.json';

library.add(faEnvelope, faChevronDown, faChevronUp, faPlayCircle);

Vue.component('fa', FontAwesomeIcon);


let dictionary = {
    en: {messages: en.messages},
    es: {messages: es.messages},
    nl: {messages: nl.messages},
    it: {messages: it.messages},
    de: {messages: de.messages},
    nn_NO: {messages: nn_NO.messages},
    pt_PT: {messages: pt_PT.messages},
    sv: {messages: sv.messages},
    da: {messages: da.messages},
    el: {messages: el.messages},
    fr: {messages: fr.messages},
    ru: {messages: ru.messages},
    fi: {messages: fi.messages},
    ja: {messages: ja.messages},
};

let lang;
lang = window.current_locale;
if (lang === 'no') {
    lang = 'nn_No';
}
if (lang === 'pt') {
    lang = 'pt_PT';
}
if (lang === 'us' || lang === 'en-GB'){
    lang = 'en';
}
localize(String(lang), dictionary[lang]);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.prototype.$lang = $lang;
Vue.component(GbMenu.name, GbMenu);
window.header = new Vue({
    el: '#gbTopMenu',
    data: () => {
        return {
            openLogin: false,
        }
    },
    methods: {
        isLoggedIn() {
            window.isLoggedIn = true;
        },
        updateLogin(){
            this.openLogin = window.openLogin = true;
        }
    }
});
