<template>
    <div class="multiEditPopup animated fadeInUpShort go apopover" v-if="showPopup">
        <div class="popoverInnerWhite">
            <button class="close2" v-on:click="doRemount">&nbsp;</button>
            <h1 class="editMultiAssetsPopupLeftAlign">Edit Assets <small>{{ assets.counted }} Items</small></h1>
            <br/>
            <br/>
            <form method="post" :action="formAction">
                <input type="hidden" name="_token" :value="csrf">
                <div class="description">
                    <fieldset class="editMultiAssetsPopupLeftAlign">
                        <legend>Description</legend>
                        <div class="form-group">
                            <input type="checkbox" v-model="has_title" id="title" :value="true" name="has_title">
                            <label for="title">Title</label>
                            <input class="form-control" type="text" name="title" :disabled="!has_title"/>

                            <input type="checkbox" v-model="has_subtitle" id="subtitle" :value="true"
                                   name="has_subtitle">
                            <label for="subtitle">Subtitle</label>
                            <input class="form-control" type="text" name="subtitle"
                                   :disabled="!has_subtitle"/>
                            <div class="types">
                                <label>Type</label>
                                <ul class="typeContainer">
                                    <li class="type">{{ assets.group }}</li>
                                </ul>
                            </div>

                            <input type="checkbox" v-model="has_media_type" id="type" :value="true"
                                   name="has_media_type" @change="sendMediaTypeWarning">
                            <label for="type">Media Type *</label>
                            <select class="form-control" name="media_type" :disabled="!has_media_type">
                                <option :value="null">Please Select</option>
                                <option v-for="(type, key) in assets.types" :value="key">{{ type }}</option>
                            </select>
                        </div>
                    </fieldset>
                </div>
                <div class="visibility">
                    <fieldset class="editMultiAssetsPopupLeftAlign">
                        <legend>Visibility</legend>
                        <div class="form-group">
                            <input type="checkbox" id="has_status" name="has_status" v-model="has_status" :value="true">
                            <label for="has_status">Status *</label>
                            <select class="form-control text-capitalize" name="status" :disabled="!has_status">
                                <option v-for="(status, key) in assets.status" :value="key">
                                    {{ status }}
                                </option>
                            </select>
                            <br/>
                            <div>
                                <input type="checkbox" v-model="has_start_date" id="start_date" :value="true"
                                       name="has_start_date">
                                <label for="start_date">Start Date</label>
                                <datepicker :value="state.start_date" format="dd-MM-yyyy"
                                            input-class="form-control"
                                            :disabled="!has_start_date"
                                            name="start_date"></datepicker>

                                <input type="checkbox" id="end_date" v-model="has_end_date" :value="true"
                                       name="has_end_date">
                                <label for="end_date">End Date</label>
                                <datepicker :value="state.end_date" format="dd-MM-yyyy"
                                            input-class="form-control"
                                            :disabled="!has_end_date"
                                            name="end_date"></datepicker>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div class="permissions">
                    <fieldset class="editMultiAssetsPopupLeftAlign">
                        <legend>Permissions</legend>
                        <div class="form-group">
                            <input id="roles" type="checkbox" v-model="has_roles" :value="true" name="has_roles">
                            <label for="roles">Roles *</label>
                            <multi-select v-if="has_roles"
                                          v-model="roles"
                                          tag-placeholder="Choose roles"
                                          placeholder="Please Select"
                                          label="name"
                                          track-by="name"
                                          :close-on-select="false"
                                          :options="assets.roleSelect"
                                          :multiple="true"
                                          :taggable="true"
                            ></multi-select>
                            <select v-else :disabled="!has_roles" class="form-control">
                                <option>Please Select</option>
                            </select>
                            <input v-for="role in roles"
                                   v-if="role.value === 'all' ? !(roles = assets.roles) : true"
                                   type="hidden" name="roles[]" :value="role.value">


                            <div v-if="assets.suo && !isEmpty(assets.suo)">
                                <input id="suo" type="checkbox" v-model="has_suo" :value="true" name="has_suo">
                                <label for="suo">Specialist User Options</label>
                                <multi-select v-if="has_suo"
                                              v-model="suos"
                                              tag-placeholder="Specialist user options"
                                              placeholder="Please Select"
                                              label="name"
                                              :close-on-select="false"
                                              track-by="name"
                                              :options="assets.suoSelect"
                                              :multiple="true"
                                              :taggable="true"
                                ></multi-select>
                                <select v-else class="form-control" :disabled="!has_suo">
                                    <option>Please Select</option>
                                </select>
                                <input v-for="suo in suos" v-if="suo.value === 'all' ? !(suos = assets.suo) : true"
                                       type="hidden" name="roles[]" :value="suo.value"/>
                            </div>

                            <input id="brands" type="checkbox" v-model="has_brands" :value="true" name="has_brands">
                            <label for="brands">Brands *</label>
                            <multi-select v-if="has_brands"
                                          v-model="brands"
                                          tag-placeholder="Choose Brands"
                                          placeholder="Please Select"
                                          label="name"
                                          :close-on-select="false"
                                          track-by="name"
                                          :options="assets.brandSelect"
                                          :multiple="true"
                                          :taggable="true"
                            ></multi-select>
                            <select v-else class="form-control" :disabled="!has_brands">
                                <option>Please Select</option>
                            </select>
                            <input v-for="brand in brands"
                                   v-if="brand.value === 'all' ? !(brands = assets.brands) : true" type="hidden"
                                   name="brands[]" :value="brand.value"/>
                        </div>
                    </fieldset>
                </div>

                <div class="about">
                    <fieldset class="editMultiAssetsPopupLeftAlign">
                        <legend>About</legend>
                        <div class="form-group">
                            <input type="checkbox" :value="true" id="about" v-model="has_about" name="has_about"/>
                            <label for="about">About</label>
                            <template v-if="has_about">
                                <input type="checkbox" id="about_source_code" :value="true" v-model="about_source_code">
                                <label for="about_source_code">Show Source Code</label>
                            </template>
                            <textarea class="form-control" :disabled="!has_about"
                                      :class="{source_code: about_source_code && has_about}"
                                      :hidden="has_about && !about_source_code" name="about">{{ about }}</textarea>
                            <ckeditor v-if="has_about && !about_source_code" :editor="editor" v-model="about"
                                      :config="editorConfig"></ckeditor>
                        </div>
                    </fieldset>
                </div>
                <div class="terms_of_use">
                    <fieldset class="editMultiAssetsPopupLeftAlign">
                        <legend>Terms of use</legend>
                        <div class="form-group">
                            <input type="checkbox" id="terms" :value="true" v-model="has_terms" name="has_terms"/>
                            <label for="terms">Terms</label>
                            <template v-if="has_terms">
                                <input type="checkbox" id="terms_source_code" :value="true" v-model="terms_source_code">
                                <label for="terms_source_code">Show Source Code</label>
                            </template>
                            <textarea class="form-control" :disabled="!has_terms"
                                      :class="{source_code: keywords_source_code}"
                                      :hidden="has_terms && !terms_source_code" name="terms">{{ terms }}</textarea>
                            <ckeditor v-if="has_terms && !terms_source_code" :editor="editor" v-model="terms"
                                      :config="editorConfig"></ckeditor>
                        </div>
                    </fieldset>
                </div>

                <div class="keywords">
                    <fieldset class="editMultiAssetsPopupLeftAlign">
                        <legend>Keywords</legend>
                        <input type="checkbox" id="keywords" :value="true" v-model="has_keywords" name="has_keywords"/>
                        <label for="keywords">Keywords</label>
                        <div class="form-group">
                            <!--                            <input type="checkbox" :disabled="!has_keywords" v-model="keywords_source_code"-->
                            <!--                                   :value="true"/>-->
                            <!--                            <template v-if="has_keywords">-->
                            <!--                                <input type="checkbox" id="keywords_source_code" :value="true"-->
                            <!--                                       v-model="keywords_source_code">-->
                            <!--                                <label for="keywords_source_code">Show Source Code</label>-->
                            <!--                            </template>-->
                            <!--                            <textarea class="form-control" :disabled="!has_keywords"-->
                            <!--                                      :class="{source_code: keywords_source_code}"-->
                            <!--                                      :hidden="has_keywords && !keywords_source_code"-->
                            <!--                                      name="keywords">{{ keywords }}</textarea>-->
                            <!--                            <ckeditor v-if="has_keywords && !keywords_source_code" :editor="editor" v-model="keywords"-->
                            <!--                                      :config="editorConfig"></ckeditor>-->
                            <textarea class="form-control source_code" name="keywords"
                                      :disabled="!has_keywords"></textarea>
                        </div>
                    </fieldset>
                </div>

                <input class="form-control" v-for="asset in asset_ids" type="hidden" name="assets[]" :value="asset"/>
                <div class="submit">
                    <button class="btn btn-success btnLong">Save</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import Datepicker from 'vuejs-datepicker';


    export default {
        name: "multi-edit",

        props: {
            current_locale: {
                type: String,
                required: true,
            },
            csrf: {
                type: String,
                required: true,
            },
            remount: {
                type: Number,
                required: true,
            }
        },
        components: {
            'multi-select': Multiselect,
            'datepicker': Datepicker,
        },
        data: () => {
            return {
                has_title: false,
                has_subtitle: false,
                has_media_type: false,
                has_start_date: false,
                has_end_date: false,
                has_roles: false,
                has_suo: false,
                has_brands: false,
                has_about: false,
                has_terms: false,
                has_keywords: false,
                has_status: false,

                about_source_code: false,
                terms_source_code: false,
                keywords_source_code: false,

                brands: null,
                suos: null,
                roles: null,
                showPopup: false,
                assets: {},
                formAction: null,
                asset_ids: null,
                about: '',
                terms: '',
                keywords: '',

                editor: ClassicEditor,
                editorConfig: {},

                state: {
                    start_date: null,
                    end_date: null,
                },
            }
        },
        mounted() {
            let multiEditObj = this;
            this.formAction = '/' + this.current_locale + '/my-admin/multi_edit/update';
            var loadAssets = setInterval(function () {
                if (window.assets) {
                    multiEditObj.asset_ids = window.assets;
                    axios.post('/' + multiEditObj.current_locale + '/multi_edit', {data: window.assets}
                    ).then(response => {
                        Swal.close();
                        if (response.data.errors) {
                            Swal.fire({
                                title: 'Type Error',
                                icon: 'error',
                                text: response.data.errors[0],
                            }).then((result) => {
                                multiEditObj.doRemount();
                            });
                        } else if (response.data) {
                            multiEditObj.showWindow();
                            multiEditObj.setAssets(response.data.assets);
                        }
                    }).catch(error => {
                        Swal.fire({
                            title: 'Unexpected Error',
                            icon: 'error',
                            text: error,
                            customClass: window.SWAL_CLASSLIST,
                        });
                    });
                    clearInterval(loadAssets);
                }
            }, 1000);
        },
        methods: {
            setAssets(assets) {
                this.assets = assets;
                let roles = this.assets.roles, brands = this.assets.brands, suo = this.assets.suo;
                this.assets.brandSelect = Array.copy(brands);
                this.assets.roleSelect = Array.copy(roles);


                this.assets.roleSelect.unshift({name: 'All', value: 'all'});
                this.assets.brandSelect.unshift({name: 'All', value: 'all'});

                if (suo.length) {
                    this.assets.suoSelect = Array.copy(suo);
                    this.assets.suoSelect.unshift({name: 'All', value: 'all'});
                }
            },
            showWindow() {
                this.showPopup = true;
            },
            doRemount() {
                window.assets = false;
                this.$emit('multieditremountfire', this.remount + 1);
            },
            isEmpty(arr) {
                return window.isEmpty(arr);
            },
            sendMediaTypeWarning(event) {
                if (event.target.checked && this.assets.group === 'Social Media') {
                    Swal.fire({
                        title: 'Warning!',
                        html: '<p>Changing Social Media media type can result in loss of resource file such as video preview file.</p>',
                        customClass: SWAL_CLASSLIST,
                        showCancelButton: true,
                        showCloseButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Continue',
                    }).then(response => {
                        console.log(response.value);
                        if (!response.value) {
                            this.has_media_type = false;
                        }
                    });
                }
            }
        },
    }
</script>
<style scoped>
    textarea.form-control {
        border-radius: 0;
        color: #eee;
        height: 100px;
    }

    textarea.form-control.source_code {
        color: #000;
    }

    ul.typeContainer {
        padding-left: 0;
    }

    li.type {
        background: lightskyblue;
        padding: 3px 10px;
        color: white;
        border-radius: 10px;
        list-style: none;
        width: fit-content;
    }

    label[for='about'], label[for='terms'], label[for='keywords'] {
        padding-right: 20px;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>