<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div v-if="alerts.errors" class="alert alert-danger">
          <p v-for="message in alerts.errors" v-html="message"></p>
        </div>
        <div v-if="alerts.success" class="alert alert-success">
          <p v-for="message in alerts.success" v-html="message"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Alerts",
    props:{
      alerts: {
        type: Object,
        required: true,
      }
    },
  }
</script>
