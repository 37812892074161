window.makeQuery = (obj) => {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

window.makeFormData = (object) => {
    let form = new FormData;
    for (let key in object) {
        form.append(key, object[key]);
    }

    return form;
};