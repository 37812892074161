<template>
    <div>
        <div>
            <div class="editThumbnailBtn">
                <button class="btn btn-dark" v-on:click="addThumbnail" v-if="!imageUrl">Add Preview</button>
                <button class="btn btn-dark" v-on:click="addThumbnail" v-else>Change Preview</button>
            </div>
            <button class="btn btn-danger closeThumbnailArea" v-if="addImage" v-on:click="doRemount">Close</button>

            <progress-bar v-if="uploadPercentage !== 0 && addImage"
                          bar-color="#C2002F"
                          :val="uploadPercentage"
                          :text="uploadPercentage + '%'"
                          text-position="inside"
                          :key="updateProgress"
                          :name="unique_key"
            ></progress-bar>

            <div class="addThumbUploaded">
                <form ref="fileform" enctype="multipart/form-data">
                    <label :for="'fileUploads' + asset" class="dropbox-label">
                        <div id="dropbox" class="animated fadeIn go" v-if="addImage">
                            <div class="svg-img">
                                <svg fill="red" xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                                     viewBox="0 0 24 24">
                                    <path d="M12 5c3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-13c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78zm0-2c-4.006 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408-.212-3.951-3.473-7.092-7.479-7.092zm4 10h-3v4h-2v-4h-3l4-4 4 4z"/>
                                </svg>
                            </div>
                            <h3>Click here to upload</h3>
                            <h5>( Or drag and drop your image )</h5>
                        </div>
                        <input :id="'fileUploads' + asset" type="file" multiple v-on:change="addFilesNoDrop($event)"
                               hidden/>
                    </label>
                </form>
            </div>
        </div>
        <img v-if="imageUrl" class="listing-thumbnail" :src="imageUrl"/>
        <!--        <img v-if="original && !imageUrl" class="listing-thumbnail" :src="original"/>-->
    </div>
</template>

<script>
    import ProgressBar from 'vue-simple-progress';

    export default {
        name: "add-thumbnail",
        props: {
            asset: {
                required: true,
                type: Number,
            },
            remount: {
                required: true,
                type: Number,
            },
            unique_key: {
                required: true,
                type: String,
            },
            wording: {
                required: true,
                type: String,
            },
            original: {
                required: false,
                type: String
            },
            current_locale: {
                required: true,
                type: String,
            }
        },
        components: {
            'progress-bar': ProgressBar
        },
        data: () => {
            return {
                addImage: false,
                updateProgress: null,
                uploadPercentage: 0,
                file: null,
                imageUrl: null,
                rules: /\.(jpe?g|png|gif)$/i
            }
        },
        mounted() {
            axios.post('/' + window.current_locale + '/check_thumbnail', {asset: this.asset, current_locale: this.current_locale}).then(response => {
                if (response.data && response.data.slug) {
                    this.addImage = false;
                    this.imageUrl = response.data.slug;
                }
            });
            this.dragAndDropCapable = this.determineDragAndDropCapable();
            if (this.dragAndDropCapable) {
                ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (evt) {
                    this.$refs.fileform.addEventListener(evt, function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                    }.bind(this), false);
                }.bind(this));
                this.$refs.fileform.addEventListener('drop', function (e) {
                    for (let i = 0; i < e.dataTransfer.files.length; i++) {
                        if (i > 1) {
                            Swal.fire({
                                title: 'Too many files',
                                type: 'error',
                                text: 'You can only upload 1 thumbnail',
                                customClass: window.SWAL_CLASSLIST,
                            });
                            break;
                        }
                        this.uploadPercentage = 0;
                        this.file = e.dataTransfer.files[i];
                    }
                    this.submitFiles();
                }.bind(this));
            } else {
                console.log('browser does not support drag & drop');
            }
        },

        methods: {
            addThumbnail(event) {
                event.preventDefault();
                this.addImage = true;
            },
            doRemount(event) {
                event.preventDefault();
                this.$emit('addthumbnailremountfire', this.remount + 1);
            },
            addFilesNoDrop(event) {
                for (var i = 0; i < event.target.files.length; i++) {
                    if (i > 0) {
                        Swal.fire({
                            title: 'Too many files',
                            type: 'error',
                            text: 'You can only upload 1 thumbnail',
                            customClass: window.SWAL_CLASSLIST,
                        });
                        break;
                    }
                    this.uploadPercentage = 0;
                    this.file = event.target.files[i];
                }
                this.submitFiles();
            },
            determineDragAndDropCapable() {
                var div = document.createElement('div');

                return (('draggable' in div)
                    || ('ondragstart' in div && 'ondrop' in div))
                    && 'FormData' in window
                    && 'FileReader' in window;
            },
            submitFiles() {
                let file = this.file;
                if (this.rules.test(file.name)) {
                    var formData = new FormData();
                    formData.append('resources', file, file.name);
                    formData.append('type', 10);
                    formData.append('asset', this.asset);
                    formData.append('current_locale', this.current_locale);
                    axios.post('/' + window.current_locale + '/add_thumbnail', formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                            onUploadProgress: function (progressEvent) {
                                var value = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                                this.uploadPercentage = value;
                                this.updateProgress = value;
                                if (this.uploadPercentage === 100) {
                                    this.addImage = false;
                                }
                            }.bind(this)
                        }
                    ).then(response => {
                        if (response.data && response.data.slug) {
                            this.imageUrl = response.data.slug;
                        }
                    }).catch(error => {
                        Swal.fire({
                            icon: 'error',
                            title: 'There was a problem uploading your file',
                            text: error,
                            customClass: window.SWAL_CLASSLIST,
                        });
                    });
                } else {
                    this.uploadPercentage = false;
                    Swal.fire({
                        title: 'Only images should be used for thumbnails',
                        icon: 'error',
                    }).then((result) => {
                        this.doRemount();
                    });
                }
            },
        },
    }
</script>

<style scoped>

</style>