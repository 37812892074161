<template>
    <div class="container-fluid sub-navbar">
        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-12">
                   <a class="subnav-logo" :href="base+'/'+locale">
                        <img v-if="locale === 'ru'" src="/images/wella-logo-cropped-old.png" alt="Wella Logo">
                        <img v-else src="/images/wella-logo-cropped.png" alt="Wella Logo">
                    </a>
                </div>
            </div>
            <div class="row">
                <ol class="subnav-links">
                    <li v-if="locale === 'en-GB'" class="hide-mobile" id="step6">
                        <a @click.prevent="$emit('tour')" class="tour-btn" v-html="$lang.trans('tours.homepage.header.trigger')"></a>
                    </li>
                    <li v-for="link in links">
                        <a :href="link.url" style="text-transform: uppercase" v-html="link.name"></a>
                    </li>
                    <li class="search-item">
                        <form @submit.prevent="search"  class="search-container">
                            <input type="text" :placeholder="trans('search')" v-model="query">
                            <button @click.prevent="search">{{ trans('go') }}</button>
                        </form>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
    import collect from 'collect.js';
    export default {
        name: "SubNavWmm",
        props: {
            locale: {
                required: true,
                type: String,
            },
            base: {
                required: true,
                type: String,
            }
        },
        data() {
            return {
                query: '',
                links: [],
            }
        },
        mounted() {
            let links = [
                {name: this.trans('home'), url: `${this.base}/${this.locale}/`},
                {name: this.trans('marketing_tools'), url: `${this.base}/${this.locale}/marketing-tools`, identifier: 'tools'},
                {name: this.trans('images_videos'), url: `${this.base}/${this.locale}/images-videos`, identifier: 'images'},
                {name: this.trans('e_content_library'), url: `${this.base}/${this.locale}/e-content`, identifier: 'e-content'},
            ];

            if(this.locale === 'ru'){
                links = collect(links).map((item) => {
                    if(item.identifier === 'e-content'){
                        if(this.locale === 'ru') {
                            item.url = 'https://wella.brandquad.ru/cty/20fc7ea3-fa9a-49db-a59e-c923106d12d4/';
                        }
                    }
                    return item;
                }).push({name: this.trans('get_help'), url: 'mailto:wellaprofrus@cotyinc.com'}).toArray();
            }

            this.links = links;



        },
        methods: {
            search() {
                window.location.href = `${this.base}/${this.locale}/search?q=${this.query}`;
            },
            trans(key, replacements = null) {
                return this.$lang.trans(`general.${key}`, replacements);
            }
        }
    }
</script>
