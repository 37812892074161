<template>
    <div class="account-settings-wrapper">
        <div class="centerIt">
            <h4>{{ trans('welcome_back_strap') }}</h4>
            <div class="setting-links">
                <a :href="`${base}/${locale}/account/profile`" class="setting-link">
                    <img src="/assets/images/common/iconDashUpdate.svg" alt="">
                    <p>{{ trans('update_my_details') }}</p>
                </a>
                <a :href="`${base}/${locale}/account/password`" class="setting-link">
                    <img src="/assets/images/common/iconDashPass.svg" alt="">
                    <p>{{ trans('change_password') }}</p>
                </a>
                <a :href="`${base}/${locale}/account/email`" class="setting-link">
                    <img src="/assets/images/common/iconDashEmail.svg" alt="">
                    <p>{{ trans('change_email') }}</p>
                </a>
                <a :href="`${base}/${locale}/account/logos`" class="setting-link">
                    <img src="/assets/images/common/iconDashLogo.svg" alt="">
                    <p>{{ trans('manage_logos') }}</p>
                </a>
                <a :href="`${base}/${locale}/account/customisations`" class="setting-link">
                    <img src="/assets/images/common/iconDashCustHist.svg" alt="">
                    <p>{{ trans('customisation_history') }}</p>
                </a>
                <a :href="`${base}/${locale}/account/downloads`" class="setting-link">
                    <img src="/assets/images/common/iconDashDownHist.svg" alt="">
                    <p>{{ trans('download_history') }}</p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AccountSettings",
        props: {
            base:{
                type: String,
                default: 'https://marketing.wella.com',
            },
            locale:{
                type: String,
                default: 'en',
            },
            name:{
                type: String,
                default: '',
            }
        },
        methods:{
            trans(key, replacements = null) {
                return this.$lang.trans(`general.${key}`, replacements);
            }
        }
    }
</script>
