<template>
    <div class="login-wrapper">
        <h2 v-if="multidownload" class="multidownload-notice">{{ $lang.trans('multidownload.login.title')}}</h2><br>
        <div class="login-form">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)">
                    <div class="form-group">
                        <div class="form-header">{{ trans('sign_in') }}</div>
                        <validation-provider :name="trans('email')" :rules="{required: true}" v-slot="{ errors }">
                            <input class="form-input required" type="email"
                                   :placeholder="trans('email')"
                                   v-model="customer.email">
                            <p class="error">{{ errors[0] }}</p>
                        </validation-provider>
                        <validation-provider :name="trans('password')" :rules="{required: true}" v-slot="{ errors }">
                            <input class="form-input required" type="password"
                                   :placeholder="trans('password')"
                                   v-model="customer.password">
                            <p class="error">{{ errors[0] }}</p>
                        </validation-provider>
                        <br>
                        <p class="error" v-for="error in errors">{{ error }}</p>
                        <input type="checkbox" id="remember" v-model="customer.remember" />
                        <label for="remember" class="remember">{{ trans('remember_me') }}</label><br>
                        <button type="button" @click.prevent="handleSubmit(submit)">{{ trans('login') }}</button>
                        <br><br>
                        <a :href="`/${locale}/password/forgot`" class="forgot-password">{{ trans('forgot_link') }}</a>
                    </div>
                </form>
            </ValidationObserver>
        </div>
        <div class="register-form">
            <div class="form-group">
                <div class="form-header">{{ trans('not_registered') }}</div>
                <p class="register-msg" v-html="trans('dash_register_intro')"></p>
                <button v-if="locale !== 'ru'" type="button" @click.prevent="goToReg">{{ trans('register_now') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate/dist/vee-validate.full';

    export default {
        name: "login",
        data() {
            return {
                customer: {
                    email: null,
                    password: null,
                    remember: 0,
                },
                errors: [],
            }
        },
        props:{
            base: {
                required: true,
                type: String,
            },
            locale: {
                required: true,
                type: String,
            },
            multidownload: {
                type: Boolean,
                default: false,
            }
        },
        components: {
            ValidationObserver,
            ValidationProvider,
        },
        watch:{
            email(){
                this.errors = [];
            },
            password(){
                this.errors = [];
            }
        },
        methods: {
            trans(key, replacements = {}) {
                return this.$lang.trans(`general.${key}`, replacements);
            },
            submit() {
                this.errors = [];
                axios.post('/api/login', window.makeFormData(this.customer)).then(response => {
                    if (response.data.id) {
                        this.$emit('login', response.data);
                    }
                    else{
                        this.errors.push(response.data.errors);
                    }
                }).catch(err => {
                    this.errors.push(err);
                });
            },
            goToReg() {
                window.location.href= `${this.base}/${this.locale}/register/form`;
            },
        },
    }
</script>
