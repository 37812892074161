export const fireEvent = (name, event, eventAction, details = {}) => {
    let theEvent = (new CustomEvent(name, {
        detail: {
            event: event,
            eventAction: eventAction,
            eventType: "userEvent",
            env: window.location.host === 'marketing.wella.com' ? 'live' : 'stage',
            version: '1.0.0',
            ...details
        }
    }));
    document.body.dispatchEvent(theEvent);
    console.log(theEvent);
    return theEvent;
};