<template>
    <div>
        <div class="tour-banner-disabled" v-if="locale === 'ja'">
            WELLA
        </div>
        <div class="tour-banner" @click.prevent="startTour" v-else>
<!--          {{ trans('register_enjoy_4') }}-->
            <span>NEW FEATURES</span> TOUR Matt<fa style="font-size: 22px; margin: 0 0 -2px 5px;" :icon="['far','play-circle']"/>
        </div>
        <div class="mobile-nav">
            <div class="hamburger" v-if="!navOpen" @click.prevent="navOpen = true">
                <img src="/images/hamburger.svg" alt="MENU">
            </div>
            <div class="hamburger" v-else @click.prevent="navOpen = false">
                <img src="/images/mobile_exit.svg" alt="CLOSE">
            </div>
            <a :href="`${base}/${locale}`" class="logo">
                <img v-if="locale === 'ru'" src="/images/wella-logo-cropped-old.png" alt="Wella Logo">
                <img v-else src="/images/wella-logo-cropped.png" alt="Wella Logo">
            </a>
        </div>
        <transition name="slide-down">
            <div class="mobile-nav-content" v-if="navOpen">
                <div class="main-links" v-if="locale === 'en-GB'">
                    <span class="main-link active"><span class="accent">MY</span>MARKETING</span>
                    <a :href="`${base}/${locale}/my-salon-advantage`" class="main-link"><span class="accent">MY</span>SALON<br>ADVANTAGE</a>
                    <a :href="`${base}/${locale}/my-education-fund`" class="main-link"><span class="accent">MY</span>EDUCATION<br>FUND</a>
                    <a :href="`${base}/${locale}/system-professional-rewards`" class="main-link"><span class="accent">SP</span> REWARDS</a>
                </div>
                <div class="sub-links" v-if="page === 'wmm'">
                    <a v-for="link in links" :href="link.url" class="sub-link" style="text-transform: uppercase">
                        {{ link.name }}</a>
                </div>
                <hr style="margin: 0 10px 0 10px; border-color: #e9e9e9;">
                <div class="extra-links">
                    <a @click.prevent="showDrop = !showDrop" target="_blank"><fa v-if="!showDrop" icon="chevron-down" /><fa v-else icon="chevron-up" /> SALON SUPPORT</a>
                    <div class="mobile-drop" v-if="showDrop">
                        <a href="https://uk.wellastore.com/" target="_blank">Wella Store</a>
                        <a href="https://education.wella.com/?cb_country=uk" target="_blank">Education</a>
                        <a href="https://managemysalon.wella.com/en-UK/login" target="_blank">Salon Finder Manager</a>
                    </div>
                    <a href="https://www.wella.com/professional/en-UK/subscribe" target="_blank"><fa icon="envelope" /> NEWSLETTER</a>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "MobileHeader",
        props: ['user', 'base', 'locale'],
        data() {
            return {
                navOpen: false,
                page: 'wmm',
                links: [],
                showDrop: false,
            }
        },
        mounted() {
            this.links.push(
                {name: this.trans('home'), url: `${this.base}/${this.locale}/`},
                {name: this.trans('marketing_tools'), url: `${this.base }/${this.locale}/marketing-tools`},
                {name: this.trans('images_videos'), url: `${this.base}/${this.locale}/images-videos`},
                {name: this.trans('e_content_library'), url: `${this.base}/${this.locale}/e-content`},
            );
        },
        methods: {
            trans(key, replacements = {}) {
                return this.$lang.trans(`general.${key}`, replacements);
            },
            startTour(){
                document.cookie = "tutorial=start";
                document.location.href = `${this.base}/${this.locale}/search?q=blank`;
            },
        },
        watch:{
            navOpen(){
                if (this.navOpen){
                    this.$emit('closeLogin');
                }
            }
        },
    }
</script>
