$(".dropdown").hover(
    function () {
        $('.dropdown-menu', this).stop().fadeIn("fast");
    },
    function () {
        $('.dropdown-menu', this).stop().fadeOut("fast");

    });

//  For Mobile nav hide/show
$(".mobMenuBtn").on("click", function () {
    $(".mobMenu").toggleClass("mobMenu-change");
});

// MOBILE NAV Expands the nested UL for the LI being clicked -->
$(".dropdown-toggle").on("click", function (e) {
    if ($(window).width() >= 992) {
        return;
    }
    $ul = $(this).nextAll('ul').eq(0);
    if ($ul.length) {
        $ul.toggleClass('open').slideToggle();
    }
});