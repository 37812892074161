<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="download-restrictions animated fadeInDownShort go">
          <h1>Download Your File</h1>

          <div class="div redBar">
            <h2>Usage Restrictions</h2>
          </div>

          <div class="greyBody">
            <p class="restrictionsIntro">By downloading these files you agree to the following restrictions</p>
            <hr/>

            <div v-if="groups" v-for="(group, key) in groups">
              <h3 v-if="key">
                The following assets can only be used until <span>{{ moment(key).format('DD-MM-yyyy') }}</span> and may
                have special terms
              </h3>
              <h3 v-else>Please review the terms & conditions of these assets</h3>

              <div class="assetCont" v-for="asset in group" v-if="key || asset.terms_copy">
                <div class="assetDrawer">
                  <h4>{{ asset.title }}</h4>
                  <a v-if="asset.open" @click.prevent="asset.open = false">View Less <span class="icon"><img
                      class="img-responsive" src="/images/chevron-up-solid.svg" alt="arrow pointing up"></span></a>
                  <a v-if="!asset.open" @click.prevent="asset.open = true">View Asset &amp; Terms <span
                      class="icon"><img
                      class="img-responsive" src="/images/chevron-down-solid.svg" alt="arrow pointing down"></span></a>
                </div>
                <div class="assetDrawerImageCont" :class="{isHidden: !asset.open}">
                  <div class="row">
                    <div class="col-sm-3">
                      <img :src="getImage(asset)" alt="Preview Download"/>
                    </div>
                    <div class="col-sm-9">
                      <div class="termsRestrictionsText">
                        <div v-html="asset.terms_copy"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="restrictionButtonsCont">
              <button class="buttonPositive" @click.prevent="$emit('accept')">Accept</button>
              <button class="buttonGrey" @click.prevent="$emit('cancel')">Cancel</button>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import collect from 'collect.js';
import {substring} from "@uwebpro/substring/substring";

export default {
  name: "DownloadRestrictions",
  props: {
    id: {
      required: true,
      type: Number,
    }
  },
  data: () => {
    return {
      groups: null,
      substring: substring,
    }
  },
  beforeDestroy() {
    document.getElementById('listingContent').style.display = 'block';
    this.$root.$el.parentNode.removeChild(this.$root.$el)
  },
  mounted() {
    document.getElementById('listingContent').style.display = 'none';
    axios.get(`/api/multi-download/${window.current_locale}/restrictions/${this.id}`).then(response => {
      if (response.data) {
        this.groups = response.data;
        window.scrollTo(0, 0);
        document.body.scrollTop = 0; // For Safari
      } else {
        this.$emit('accept', 1);
      }
    });
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    getImage(asset) {
      return collect(asset.resources).where('type', 101).first()?.preview;
    }
  }
}
</script>
