import {steps} from "./steps";

export const tOptions = {
    nextLabel: __('tours.tour.buttons.next'),
    prevLabel: __('tours.tour.buttons.previous'),
    skipLabel: __('tours.tour.buttons.skip'),
    doneLabel: __('tours.tour.buttons.done'),
    hideNext: true,
    hidePrev: true,
    showStepNumbers: false,
    scrollToElement: true,
    disableInteraction: true,
    showBullets: false,
    highlightClass: 'introWalkHighlight',
    tooltipClass: 'introWalkToolTip',
    tooltipPosition: 'auto',
    steps: steps,
};