;(function() {
    connectToCustomer = function() {
        var frm = $(this).closest("form");
        var next = $(this).data('next');
        if(!$("#connect_customer_number").val()) {
            alert("please enter a customer number");
            return;
        }
        $.ajax({
            type: "POST",
            url: frm.attr("action"),
            data: frm.serialize(),
            success: function(resp){
                if(resp.message === 'success') {
                    connectionSuccess(next);
                } else {
                    $("#customer-form").hide();
                    $("#customer-error-message").text(resp.message);
                    $("#customer-error").show();
                }
            },
            dataType: 'json'
        });
    }

    connectionSuccess = function(next) {
        var personalisable = $('[data-personalise]');
        if(personalisable.length) {
            next = personalisable.data('personalise');
        }
        window.location = next;
    }
    $('[data-submit="customer-connect"]').on("click",connectToCustomer);
})();