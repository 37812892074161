;(function() {
    toggleGroup = function(ev) {
        ev.preventDefault();
        var group = $(this).closest('.toggle-group');

        if(anySelected(group)) {
            cValue = false;
        } else {
            cValue = true;
        }
        group.find('input:checkbox').prop('checked',cValue);
    }

    anySelected = function(group) {
        return group.find("input:checked").length;
    }

    $("button.toggle-all").on("click",toggleGroup);
})();