<template>
    <div>
        <div class="head-bar-wrapper">
            <div class="head-bar" v-if="locale === 'en-GB'">
                <a v-if="!showDrop" @click.prevent="showDrop = !showDrop" style="cursor:pointer;">
                    <fa icon="chevron-down"/>
                    Salon Support</a>
                <a v-if="showDrop" @click.prevent="showDrop = !showDrop" style="cursor:pointer;">
                    <fa icon="chevron-up"/>
                    Salon Support</a>
                <a href="https://www.wella.com/professional/en-UK/subscribe" target="_blank">
                    <fa icon="envelope"/>
                    Newsletter</a>
                <a :href="`${base}/${locale}`" class="highlighted"
                   :class="{'active': location === `${base}/${locale}` || location === `${base}/${locale}/`}">
                  <span class="accent">My</span>Marketing
                </a>
                <a :href="`${base}/${locale}/my-salon-advantage`"
                   class="highlighted"><span class="accent">My</span>Salon
                    Advantage</a>
                <a :href="`${base}/${locale}/my-education-fund`"
                   class="highlighted"><span class="accent">My</span>Education Fund</a>
                <a :href="`${base}/${locale}/system-professional-rewards`"
                   class="highlighted"
                   :class="{'active': location === `${base}/${locale}/system-professional-rewards` || location === `${base}/${locale}/system-professional-rewards/`}">
                  <span class="accent">SP</span> Rewards
                </a>
            </div>
            <div v-else-if="locale !== 'ja' && locale !== 'en-GB'" id="step6" class="head-bar">
                <a @click.prevent="startTour" class="tour-btn-banner"
                   v-html="$lang.trans('tours.homepage.header.trigger')"></a>
            </div>
            <div class="login-toggle">
                <span v-if="locale === 'en-GB'"><img class="header-flag" src="/images/gb_flag.svg" alt="en-GB"></span>
                <span v-if="!user && !showLogin" @click.prevent="toggleLogin"
                      class="hover-red">{{ trans('login') }}</span>
                <span v-if="!user && showLogin" @click.prevent="toggleLogin"
                      class="hover-red">{{ trans('close') }}</span>
                <a v-if="user && !showSettings" @click.prevent="showSettings = !showSettings" class="hover-red">{{
                    trans('my_account') }}</a>
                <a v-if="user && showSettings" @click.prevent="showSettings = !showSettings" class="hover-red">{{
                    trans('close') }}</a>
                <span v-if="!user && locale !== 'ru'"> / </span>
                <a v-if="!user && locale !== 'ru'" :href="`${base}/${locale}/register/form`" class="hover-red">{{ trans('register') }}</a>
                <a :href="`/${locale}/my-admin`" class="hover-red" v-if="user && user.type < 300">{{ trans('admin') }} /</a>
                <span v-if="user" @click.prevent="logout"
                      class="hover-red">{{ trans('logout') }} <span class="hide-mobile">{{ user.email }}</span></span>
            </div>
        </div>
        <sub-nav-wmm :base="base" :locale="locale" v-on:tour="startTour"></sub-nav-wmm>
        <ul class="support-dropdown" v-if="showDrop">
            <li><a href="https://uk.wellastore.com/" target="_blank">Wella Store</a></li>
            <li><a href="https://education.wella.com/?cb_country=uk" target="_blank">Education</a></li>
            <li><a href="https://managemysalon.wella.com/en-UK/login" target="_blank">Salon Finder Manager</a></li>
        </ul>

    <transition name="slide-down">
      <Login v-if="showLogin" v-on:login="login" v-on:close="toggleLogin" :base="base" :locale="locale"
             :multidownload="openLogin"></Login>
    </transition>
    <transition name="slide-down">
      <AccountSettings v-if="showSettings" v-on:close="showSettings = !showSettings"
                       :base="base" :name="user.given_name" :locale="locale"></AccountSettings>
    </transition>

    <mobile-header ref="mobileHeader" :user="user" :base="base" :locale="locale"
                   v-on:closeLogin="showLogin = false"></mobile-header>
  </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate/dist/vee-validate.full';
import Login from './Login.vue';
import AccountSettings from './AccountSettings.vue';
import SubNavWmm from './SubNavWmm';
import MobileHeader from "./MobileHeader";
import {fireEvent} from "../../fire-event";

export default {
  name: "GbMenu",
  props: {
    base: {
      required: true,
      type: String,
    },
    locale: {
      required: true,
      type: String,
    },
    importedUser: {
      required: false,
      type: Object,
      default: null,
    },
    openLogin: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  components: {
    Login,
    SubNavWmm,
    MobileHeader,
    AccountSettings,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      showLogin: false,
      showDrop: false,
      showSettings: false,
      user: null,
    }
  },
  computed: {
    location() {
      return window.location.href
    }
  },
  watch: {
    showLogin() {
      if (this.showLogin) {
        this.showDrop = false;
        this.showSettings = false;
        this.$refs.mobileHeader.navOpen = false;
      }
    },
    showDrop() {
      if (this.showDrop) {
        this.showLogin = false;
        this.showSettings = false;
      }
    },
    showSettings() {
      if (this.showSettings) {
        this.showLogin = false;
        this.showDrop = false;
      }
    },
    openLogin() {
      if (this.openLogin) {
        this.showLogin = this.openLogin;
      }
    }
  },
  mounted() {
    if (this.importedUser) {
      this.user = this.importedUser;
    }
    this.showLogin = this.openLogin;
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('click', this.handleDrop);

    console.log(this.base + this.locale)
    console.log(window.location.href)
  },
  methods: {
    toggleLogin() {
      this.showLogin = !this.showLogin;
      if (this.showLogin) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
      }
    },
    login(data) {
      this.user = data;
      this.showLogin = false;
      if (window.openLogin === true) {
        window.$('#multiDownload').submit();
      }
      this.$emit('loggedIn', data);
    },
    logout() {
      window.location.href = `/${this.locale}/logout`;
    },
    handleScroll() {
      if (this.showLogin && (window.pageYOffset > 300 || document.documentElement.scrollTop > 300 || document.body.scrollTop > 300) && window.innerWidth > 1145) {
        this.showLogin = false;
      }
    },
    handleDrop(e) {
      if (!this.$el.contains(e.target)) {
        this.showDrop = false;
      }
    },
    startTour() {
      window.Cookies.set('tutorial', 'start');
      window.StartTourEvent = fireEvent('StartTour', 'startTour', 'New Features Tour', {
        eventInfo: {
          location: 'header',
          page: window.location.pathname
        }
      });
      document.location.href = `${this.base}/${this.locale}/search?q=`;
    },
    trans(key, replacements = null) {
      return this.$lang.trans(`general.${key}`, replacements);
    }
  }
}
</script>
