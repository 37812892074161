;(function() {
    let len, data
    $.ajaxSetup({cache: false});

    const pushStateAllowed = window.history && window.history.pushState && window.history.replaceState &&
        !navigator.userAgent.match(/((iPod|iPhone|iPad).+\bOS\s+[1-4]\D|WebApps\/.+CFNetwork)/);
    let pushStateUrl;

    let typeSearch = function () {
        len = $(this).val().length
        if (len && len < 3) {
            return;
        }
        runQuery();
    }

    let runQuery = function () {
        data = $("#filter-form").serialize();
        pushStateUrl = $("#filter-form").attr("action") + '?' + data;
        $.ajax({
            url: $("#filter-form").attr("action"),
            data: data + '&ajax=1',
            success: updateResults,
            dataType: 'json'
        });
    }

    let updateResults = function (resp) {
        $(".paginationWrap").html(resp.pagination);
        $("#results").html(resp.results);

        if (pushStateAllowed) {
            window.history.pushState(null, "", pushStateUrl);
        }

        viewAllButtons();
    }

    let viewAll = function () {
        const fs = $(this).data('reset-checkboxes');
        $(`#${fs} input:checkbox`).prop('checked', false);
        runQuery();
    }

    let viewAllButtons = function () {
        $("#filterCont fieldset").each(function () {
            const ln = $(this).find("input[type=checkbox]:checked").length;
            const btn = $(this).find('.btn-view-all').show();
            if (ln) {
                btn.show();
            } else {
                btn.hide();
            }
        })
    }

    $(document).on("change, keyup", "input[name=q]", typeSearch);
    $(document).on("click", "button[data-reset-checkboxes]", viewAll);
    $(document).on("change", ".filter-box", runQuery);

    viewAllButtons();

})();