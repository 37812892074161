<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form>
      <div class="row">
        <div class="col-sm-12">
          <div class="publicAdmin">

            <div class="boxTitle">
              <h2>{{ trans('your_password') }}</h2>
            </div>

            <div class="row" style="margin-top: 20px;" v-if="alerts">
              <div class="col-sm-12">
                <div v-if="alerts.errors" class="alert alert-danger">
                  <p v-for="error in alerts.errors">{{ error }}</p>
                </div>
                <div v-if="alerts.success" class="alert alert-success">
                  <p v-for="error in alerts.success">{{ error }}</p>
                </div>
              </div>
            </div>

            <div class="boxBody">
              <div class="row">
                <div class="col-sm-12">
                  <p>{{ trans('change_password_for_you') }}.</p>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <h4>{{ trans('update_your_password') }}:</h4>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6">
                  <label for="current_password"
                         class="visuallyhidden">{{ trans('your_current_password') }}</label>

                  <validation-provider :name="trans('your_current_password')"
                                       :rules="{required: true}"
                                       v-slot="{ errors }">
                    <input id="current_password" type="password" v-model="current_password"
                           :placeholder="trans('your_current_password')" autocomplete="off"
                           required>
                    <p class="error">{{ errors[0] }}</p>
                  </validation-provider>
                </div>
              </div>

              <div class="row">

                <div class="col-sm-6">
                  <label for="password"
                         class="visuallyhidden">{{ trans('your_new_password') }}</label>
                  <validation-provider :name="trans('your_new_password')"
                                       :rules="{required: true, confirmed:'confirmation'}"
                                       v-slot="{ errors }">
                    <input id="password" type="password" v-model="password"
                           :placeholder="trans('your_new_password')" autocomplete="off"
                           required>
                    <p class="error">{{ errors[0] }}</p>
                  </validation-provider>
                </div>

                <div class="col-sm-6">

                  <label for="password_confirmation"
                         class="visuallyhidden">{{ trans('confirm_password') }}</label>
                  <validation-provider :name="trans('confirm_password')" vid="confirmation"
                                       :rules="{required: true}"
                                       v-slot="{ errors }">
                    <input id="password_confirmation" type="password" v-model="password_confirmation"
                           :placeholder="trans('confirm_password')" autocomplete="off"
                           required>
                    <p class="error">{{ errors[0] }}</p>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="publicAdmin">

            <div class="boxBodyWht">

              <div class="buttonWrapper">

                <button class="btnPositive" @click.prevent="submit" id="">{{ trans('save_and_update') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import Alerts from "./Alerts";

export default {
  name: "ChangePassword",
  components: {
    Alerts
  },
  data() {
    return {
      current_password: '',
      password: '',
      password_confirmation: '',
      alerts: null,
    }
  },
  methods: {
    trans(key, replacements = {}) {
      return this.$lang.trans(`general.${key}`, replacements);
    },
    submit() {
      let data = {
        current_password: this.current_password,
        password: this.password,
        password_confirmation: this.password_confirmation,
      };
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.alerts = {
            errors: [this.trans('please_review_errors')],
          };
        } else {
          axios.post('/api/account/password', window.makeFormData(data)).then(response => {
            if (response.data.success) {
              this.alerts = {
                success: response.data.success,
              };
              this.current_password = "";
              this.password = "";
              this.password_confirmation = "";
            }
            if (response.data.errors) {
              this.alerts = {
                errors: response.data.errors,
              };
            }
          });
        }
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        if ('scrollRestoration' in window.history) {
          window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);
      });
    },
  }
}
</script>
