<template>
    <div>
        <fieldset>
            <legend>Select File Type</legend>

            <div class="form-group">
                <label for="file_type">Select File Type</label>
                <select id="file_type" name="file_type" v-model="file_type" class="form-control">
                    <option selected :value="null">Please Select</option>
                    <option :value="10">Image</option>
                    <!--                <option :value="20">Artwork</option>-->
                    <option :value="30">File</option>
                    <option :value="40">Video</option>
                    <option :value="50">Social Media</option>
                </select>
            </div>
        </fieldset>


        <div class="alertBrand">
            <p>
                <span v-if="file_type">Allowed Types: {{ allowed[file_type] }}, </span> Maximum upload: 3.5GB Per File, 50 files at once
            </p>
        </div>
        <form ref="fileform" enctype="multipart/form-data">
            <label for="fileUploads" class="dropbox-label">
                <div id="dropbox" v-if="file_type" class="animated fadeIn go">
                    <div class="svg-img">
                        <svg fill="red" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24">
                            <path d="M12 5c3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-13c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78zm0-2c-4.006 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408-.212-3.951-3.473-7.092-7.479-7.092zm4 10h-3v4h-2v-4h-3l4-4 4 4z"/>
                        </svg>
                    </div>
                    <h3>Click here to upload</h3>
                    <h5>( Or drag and drop your files )</h5>
                </div>
                <input id="fileUploads" type="file" multiple v-on:change="addFilesNoDrop($event)" hidden/>
            </label>
        </form>

        <div v-if="!isEmpty(files)" class="apopover animated fadeInUpShort go">
            <div class="popoverInner">
                <button class="close2" v-on:click="doRemount">&nbsp;</button>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <h2>FILE UPLOAD</h2>
                            <p class="popupParagraph">Please wait whilst we upload <strong>{{ files.length }}
                                files.</strong> This may take a few minutes due to the size of the files.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-5">
                            <h5 class="titles">FILE NAME</h5>
                        </div>
                        <div class="col-sm-3">
                            <h5 class="titles">STATUS</h5>
                        </div>
                        <div class="col-sm-2"></div>
                    </div>

                    <div v-for="(file, key) in files" class="file-listing">
                        <div class="row">
                            <div class="col-sm-2"></div>
                            <div class=col-sm-5>
                                <div class="filename">
                                    <p class="multiUploadFileName">{{ file.name }}</p>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="progressContainer">
                                    <progress-bar
                                            v-if="uploadPercentage[key] !== false && !(uploadPercentage[key] <= 0) && uploadPercentage[key] !== 99 && uploadPercentage[key] !== 100 && !isString(uploadPercentage[key])"
                                            bar-color="#C2002F"
                                            :val="uploadPercentage[key]"
                                            :text="uploadPercentage[key] + '%'"
                                            :title="file.name"
                                            text-position="inside"
                                            :key="updateProgress"
                                            text-fg-color="#fff"
                                    ></progress-bar>
                                    <div :key="updateProgress" v-else-if="uploadPercentage[key] <= 0">
                                        <p class="statusMessage">Awaiting...</p>
                                    </div>
                                    <div v-else-if="uploadPercentage[key] === 99">
                                        <p class="statusMessage">Processing... <span class="spinner"></span></p>
                                    </div>
                                    <div v-else-if="uploadPercentage[key] >= 100">
                                        <p class="statusMessage">Processed</p>
                                    </div>
                                    <span class="error" style="color: red" v-else>{{ uploadPercentage[key] }}</span>
                                </div>
                                <div class="removeContainer" v-if="uploadPercentage[key] < 98">
                                    <a class="remove close" v-on:click="removeFile( key )">&nbsp;</a>
                                </div>
                            </div>
                            <div class="col-sm-2"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <p v-if="!nextDisabled" style="color:red; margin-bottom:0; margin-top:40px">Some files
                                may still be processing in the background, it may take a few minutes before all uploaded
                                resources become available on Assets page</p>
                            <a class="btn btn-success btnLong" v-if="!nextDisabled"
                               :href="`/${current_locale}/my-admin/assets`">Next</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ProgressBar from 'vue-simple-progress'

    export default {
        name: "multi-upload",

        props: {
            current_locale: {
                required: true,
                type: String
            },
            remount: {
                required: true,
                type: Number,
            },
        },
        components: {
            'progress-bar': ProgressBar
        },
        data: () => {
            return {
                file_type: null,
                dragAndDropCapable: false,
                files: [],
                uploadPercentage: [],
                requests: [],
                rules: {
                    10: /\.(jpe?g|png|gif)$/i, //
                    40: /\.(mp4)$/i,
                    30: /\.(pdf|zip|mp4|jpe?g|png|gif|docx?|pptx?|xlsx?)$/i,
                    20: /\.(jpe?g|png|gif)$/i,
                    50: /\.(jpe?g|png|gif|mp4|zip)$/i,
                },
                allowed: {
                    10: 'JPG, JPEG, PNG, GIF',
                    40: 'MP4',
                    30: 'PDF, ZIP, MP4, JPEG, JPG, PNG, GIF, DOCX, DOC, PPTX, XLSX',
                    20: 'JPEG, PNG, GIF',
                    50: 'JPEG, PNG, GIF, MP4, ZIP'
                },
                updateProgress: null,
                nextDisabled: true,
            };
        },
        mounted() {
            this.dragAndDropCapable = this.determineDragAndDropCapable();

            if (this.dragAndDropCapable) {
                ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (evt) {
                    this.$refs.fileform.addEventListener(evt, function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                    }.bind(this), false);
                }.bind(this));
                this.$refs.fileform.addEventListener('drop', function (e) {
                    for (let i = 0; i < e.dataTransfer.files.length; i++) {
                        this.uploadPercentage.push(0);
                        this.files.push(e.dataTransfer.files[i]);
                    }
                    this.submitFiles();
                }.bind(this));
            }
        },

        methods: {
            addFilesNoDrop(event) {
                for (let i = 0; i < event.target.files.length; i++) {
                    this.uploadPercentage.push(0);
                    this.files.push(event.target.files[i]);
                }
                this.submitFiles();
            },
            determineDragAndDropCapable() {
                let div = document.createElement('div');

                return (('draggable' in div)
                    || ('ondragstart' in div && 'ondrop' in div))
                    && 'FormData' in window
                    && 'FileReader' in window;
            },
            async submitFiles() {
                const iterator = this.files.keys();
                this.nextDisabled = true;
                var i = 0;
                for (const key of iterator) {
                    i++;
                    let file = this.files[key];
                    if (this.rules[this.file_type].test(file.name) && !(file.size > 200000000)) {
                        let formData = new FormData();
                        formData.append('resources', file, file.name);
                        formData.append('type', this.file_type);
                        formData.append('current_locale', this.current_locale);
                        if (!(key % 3) && key >= 2) {
                            await this.process(formData, key);
                        } else {
                            this.process(formData, key);
                        }
                    } else if (file.size > 3500000000) {
                        this.uploadPercentage[key] = 'Files over 3.5GB, will not be uploaded';
                    } else {
                        this.uploadPercentage[key] = 'Incorrect file type, will not be uploaded';
                    }
                }
            },

            removeFile(key) {
                if (this.requests[key]) {
                    this.requests[key].cancel(`Upload ${key} cancelled`);
                }
                this.files.splice(key, 1);
                this.uploadPercentage.splice(key, 1);
                if (!this.files.length) {
                    this.doRemount();
                }
            },

            async process(formData, key) {
                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();
                this.requests.push(source);
                let request = await axios.post(`/${window.current_locale}/multi_upload`, formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        cancelToken: source.token,
                        onUploadProgress: function (progressEvent) {
                            var value = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)) - 1;
                            this.uploadPercentage[key] = value;
                            this.updateProgress = value;
                        }.bind(this)
                    }
                ).then(response => {
                    this.uploadPercentage[key] = 100;
                    this.updateProgress = 0;
                    if (this.uploadPercentage.every((val, i, arr) => val === arr[0])) {
                        this.nextDisabled = false;
                    }
                }).catch(error => {
                    this.uploadPercentage[key] = 100;
                    if (this.uploadPercentage.every((val, i, arr) => val === arr[0])) {
                        this.nextDisabled = false;
                    }
                    this.updateProgress = 0;
                });
            },

            isEmpty(obj) {
                return window.isEmpty(obj);
            },
            doRemount(event = null) {
                if (event) {
                    event.preventDefault();
                }
                this.$emit('remountmultiuploader');
            },
            isString(value) {
                return typeof value === 'string' || value instanceof String;
            }
        }
    };
</script>
<style scoped>
    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }

    .spinner:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid #ccc;
        border-top-color: #000;
        animation: spinner .6s linear infinite;
    }

    p.multiUploadFileName, .statusMessage {
        word-wrap: break-word !important;
    }
</style>