import "@babel/polyfill";
import Datepicker from "vuejs-datepicker";
import Vue from "vue";
import $lang from './translations';
// import * as VeeValidate from 'vee-validate';
import MultiEdit from "./components/MultiEdit";
import MultiUpload from "./components/MultiUpload";
import AddThumbnail from "./components/AddThumbnail";
import DeleteAsset from "./components/DeleteAsset";
import VueSweetalert2 from 'vue-sweetalert2';
//If Route is like my-admin
import CKEditor from '@ckeditor/ckeditor5-vue';
//endif
import TextArea from "./components/TextArea";
import Register from "./components/GB/Register";
import Maintenance from "./components/GB/Maintenance";
import SendPasswordReset from "./components/SendPasswordReset";
import ResetPassword from "./components/ResetPassword";
import ChangePassword from "./components/ChangePassword";

/**
 * Require the scripts required for our application
 *
 */
window.$ = window.jQuery = require('jquery'); //^3.2
window.moment = require('moment');
require('bootstrap');
const Swal = require('sweetalert2');
const Cookies = require('js-cookie');
let ES6Promise = require("es6-promise");
ES6Promise.polyfill();
const axios = require('axios');
window.Parsley = require('./packages/parsley/parsley');


require('./packages/parsley/de');
require('./packages/parsley/el');
require('./packages/parsley/es');
require('./packages/parsley/fr');
require('./packages/parsley/it');
require('./packages/parsley/ja');
require('./packages/parsley/nl');
require('./packages/parsley/pt');
require('./packages/parsley/ru');
require('./packages/parsley/zh');
// window.tinymce = require('tinymce'); // uncommenting this has the potential to break the admin panel.
window.Swal = Swal;
window.Cookies = Cookies;
window.axios = axios;
window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': document.querySelector('meta[name="token"]').getAttribute('content'),
};

window.SWAL_CLASSLIST = {
    container: 'sweetWalkOverlay',
    popup: 'sweetWalkPopup',
    header: 'sweetWalkHeader',
    title: 'sweetWalkTitle',
    closeButton: 'sweetWalkCloseBTN',
    icon: 'sweetWalkIcon',
    image: 'sweetWalkImage',
    content: 'sweetWalkContent',
    input: 'sweetWalkInput',
    actions: 'sweetWalkActions',
    confirmButton: 'sweetWalkConfirmBTN',
    cancelButton: 'sweetWalkCancelBTN',
    footer: 'sweetWalkFooter'
};
window.isEmpty = (obj) => {
    if (obj == null) return true;
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;
    if (typeof obj !== "object") return true;
    for (let key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
};

window.Array.copy = (array) => {
    let ar2 = []; // create empty array to hold copy

    for (let i = 0, len = array.length; i < len; i++) {
        ar2[i] = {}; // empty object to hold properties added below
        for (let prop in array[i]) {
            ar2[i][prop] = array[i][prop]; // copy properties from arObj to ar2
        }
    }
    return ar2;
};

window.setDeleteListFalse = () => {
    window.deleteList = false;
};


Vue.prototype.$lang = $lang;

Vue.use(VueSweetalert2);
//If Route is like my-admin
Vue.use(CKEditor);
//endif
Vue.component(MultiEdit.name, MultiEdit);
Vue.component(MultiUpload.name, MultiUpload);
Vue.component(AddThumbnail.name, AddThumbnail);
Vue.component(DeleteAsset.name, DeleteAsset);
Vue.component(TextArea.name, TextArea);
Vue.component(Register.name, Register);
Vue.component(Maintenance.name, Maintenance);
Vue.component(SendPasswordReset.name, SendPasswordReset);
Vue.component(ResetPassword.name, ResetPassword);
Vue.component(ChangePassword.name, ChangePassword);

require('./forms');
require('./gb');

//
// if (document.body.contains(document.getElementById('app'))) {
    const app = new Vue({
        el: '#app',
        components: {
            'datepicker': Datepicker,
        },
        data: () => {
            return {
                multiEditRemount: 0,
                addThumbnailRemount: 0,
                multiUploadRemount: 0,
                deleteAssetRemount: 0,
                window: window,
            }
        },
        methods: {
            callLogin(data){
                window.header.$refs['gbmenu'].$data.user = data;
                window.header.$forceUpdate();
            },
            remountMultiEdit() {
                this.multiEditRemount++;
            },
            remountAddThumbnail() {
                this.addThumbnailRemount++;
            },
            remountMultiUpload() {
                this.multiUploadRemount++;
            },
            remountDeleteAsset() {
                this.deleteAssetRemount++;
            },
            saveDateRange() {
                var btn = $('#changeDateRange_Save');
                btn.attr('disabled', true).text('Saving...');

                // Get checkbox list
                var checkboxList = build_checkbox_list('.assetlist-checkbox');

                $.ajax({
                    url: '/' + window.current_locale + '/my-admin/assets/bulk/date-range',
                    dataType: 'json',
                    method: 'POST',
                    data: {
                        assets: checkboxList,
                        start_date_edit: $('#changeDateRange_chkEnableStartDate').is(':checked') ? 1 : 0,
                        end_date_edit: !!$('#changeDateRange_chkEnableEndDate').is(':checked') ? 1 : 0,
                        start_date: $('#changeDateRange_startDate').val(),
                        end_date: $('#changeDateRange_endDate').val(),
                        return_url: window.location.href,
                        _token: document.querySelector('meta[name="token"]').getAttribute('content'),
                    },
                    success: function (ret) {
                        window.location = ret.location;
                    },
                    error: function (xhr, status, error) {
                        btn.attr('disabled', false).text('Save Date Range');

                        msg_change_date_range_clear();
                        msg_change_date_range_show(xhr.responseJSON);
                    }
                });
            },
        }
    });
// }
require('./multi-download');

//New
require('./walkthrough');

//Standard
require('./assets-list');
require('./assets-view');
require('./navigation');
require('./sticky-menu');
if (document.body.contains(document.querySelector('.magnifier'))) {
    require('./magnifyer');
}
require('./pagination');

//Admin
if (document.body.contains(document.querySelector('.animated'))) {
    require('./animations');
}

if (document.body.contains(document.querySelector('.admin'))) {
    require('../adminjs/admin');
    require('../adminjs/alerts');
    require('../adminjs/assetForm');
    require('../adminjs/multi-asset-management');
    require('../adminjs/cmsForm');
    require('../adminjs/datepicker');
    require('../adminjs/locale-picker');
    require('../adminjs/tabs');
    require('../adminjs/toggler');
    require('../adminjs/validation');
}
