import Lang from 'lang.js';

const $lang = new Lang({
    messages: window.messages || {},
    locale: window.default_locale,
    fallback: 'en',
});

window.__ = (key, replacements = null) => {
    return $lang.trans(key, replacements);
}

export default $lang;