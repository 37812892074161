<template>
  <div>
    <div v-if="locale === 'ru'">
      <p class="registrationIntro">Свяжитесь с нашей службой поддержки по адресу <a href="mailto:wellaprofrus@cotyinc.com">wellaprofrus@cotyinc.com</a></p>
    </div>
    <div class="container register" v-else>
      <section>
        <div class="row" v-if="alerts === null">
          <div class="col-sm-12">
            <div class="alert alert-success">
              <p class="registrationIntro" v-html="trans('registration_intro')"></p>
            </div>
          </div>
        </div>
        <div class="row" v-else-if="alerts && alerts.errors[0].includes('failed')">
          <div class="col-sm-12">
            <div class="alert alert-danger">
              <p>{{ trans('error_occured') }}</p>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-sm-12">
            <div v-if="alerts.errors" class="alert alert-danger">
              <p v-if="alerts.errors.length">{{ trans('please_review_errors') }}</p>
            </div>
          </div>
        </div>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <div class="row">
              <div class="col-sm-12">
                <div class="publicAdmin">
                  <div class="boxTitle">
                    <h2>{{ trans('your_details') }}</h2>
                  </div>
                  <div class="boxBody">
                    <div class="row">
                      <div class="col-sm-6 required">
                        <validation-provider :name="trans('given_name')"
                                             :rules="{required: true, alpha_spaces: true}"
                                             v-slot="{ errors }">
                          <input class="form-input" type="text"
                                 v-bind:class="{ errorInput: errors[0] }"
                                 :placeholder="`${trans('given_name')} ${trans('required_ph')}`"
                                 v-model="customer.first_name">
                          <p class="error">{{ errors[0] }}</p>
                        </validation-provider>
                      </div>
                      <div class="col-sm-6 required">
                        <validation-provider :name="trans('last_name')"
                                             :rules="{required: true, alpha_spaces: true}"
                                             v-slot="{ errors }">
                          <input class="form-input required"
                                 v-bind:class="{ errorInput: errors[0] }" type="text"
                                 :placeholder="`${trans('last_name')} ${trans('required_ph')}`"
                                 v-model="customer.last_name">
                          <p class="error">{{ errors[0] }}</p>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 required">
                        <validation-provider :name="trans('country')" :rules="{required: true}"
                                             v-slot="{ errors }">
                          <select id="regCountrySelect" class="form-input" v-bind:class="{ errorInput: errors[0] }"
                                  v-model="customer.country_id">
                            <option :value="null" disabled>{{
                                trans('select_your_country')
                              }} {{ trans('required_ph') }}
                            </option>
                            <option v-for="country in countries"
                                    :value="country.id">{{ countrytrans(country.slug) }}
                            </option>
                          </select>
                          <p class="error">{{ errors[0] }}</p>
                        </validation-provider>
                      </div>
                      <div class="col-sm-6 required" v-if="hasSap() || locale === 'en-GB'">
                        <validation-provider :name="trans('ship_to_number')"
                                             :rules="{required: true, min: 8, regex: /^(10|20)/ }"
                                             v-slot="{ errors }">
                          <input class="form-input" v-bind:class="{ errorInput: errors[0] }"
                                 type="text"
                                 :placeholder="`${trans('your_ship_to_number')} ${trans('required_ph')}`"
                                 v-model="customer.ship_to">
                          <span class="sap-help"
                                @click.prevent="showSAPInfo = !showSAPInfo">?</span>
                          <p class="error" v-if="errors.length > 0">The SAP ship to is
                            incorrect. <span
                                style="text-decoration: underline; cursor: pointer"
                                @click="showSAPInfo = true">How to find it</span></p>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="row" v-if="showSAPInfo">
                      <div class="col-sm-12">
                        <img src="/images/sapInfo.jpg" alt="Help"
                             class="img-responsive sap-info">
                      </div>
                      <div class="col-sm-12">
                        <p class="sap-notice" v-html="trans('ship_to_number_cant_locate')"></p>
                        <p class="sap-notice" v-html="trans('ship_to_number_please_call')"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="publicAdmin">
                  <div class="boxTitle">
                    <h2>{{ trans('your_login_details') }}</h2>
                  </div>
                  <div class="boxBody">
                    <div class="row">
                      <div class="col-sm-12">
                        <p>{{ trans('email_address_intent') }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <h4>{{ trans('email_address') }}</h4>
                      </div>
                      <div class="col-sm-6 required">
                        <validation-provider :name="trans('email')"
                                             :rules="{ required: true, confirmed:'email_confirmation' }"
                                             v-slot="{ errors }">
                          <input class="form-input" v-bind:class="{ errorInput: errors[0] }"
                                 type="email"
                                 :placeholder="`${trans('email')} ${trans('required_ph')}`"
                                 v-model="customer.email" v-on:change="alerts = null">
                          <p class="error" v-if="errors.length > 0">{{ errors[0] }}</p>
                          <p class="error" v-if="alerts && alerts.errors[0] === 'This email is already in use'">
                            {{ $lang.trans('validation.unique', {attribute: trans('email')}) }}</p>
                        </validation-provider>
                      </div>
                      <div class="col-sm-6 required">
                        <validation-provider :name="trans('confirm_email_address')"
                                             v-slot="{ errors }"
                                             :rules="{ required: true }"
                                             :vid="'email_confirmation'">
                          <input class="form-input" v-bind:class="{ errorInput: errors[0] }"
                                 type="email"
                                 :placeholder="`${trans('confirm_email_address')} ${trans('required_ph')}`"
                                 v-model="customer.email_confirmation">
                          <p class="error">{{ errors[0] }}</p>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <h4>{{ trans('your_password') }}</h4>
                      </div>
                      <div class="col-sm-6 required">
                        <validation-provider :name="trans('password')"
                                             :rules="{ required: true, confirmed:'password_confirmation', min: 6 }"
                                             v-slot="{ errors }">
                          <input class="form-input" v-bind:class="{ errorInput: errors[0] }"
                                 type="password"
                                 :placeholder="`${trans('password')} ${trans('required_ph')}`"
                                 v-model="customer.password">
                          <p class="error" v-if="errors.length > 0">{{
                              $lang.trans('passwords.password')
                            }}</p>
                        </validation-provider>
                      </div>
                      <div class="col-sm-6 required">
                        <validation-provider :name="trans('confirm_password')"
                                             :rules="{required: true}"
                                             v-slot="{ errors }" :vid="'password_confirmation'">
                          <input class="form-input" v-bind:class="{ errorInput: errors[0] }"
                                 type="password"
                                 :placeholder="`${trans('confirm_password')} ${trans('required_ph')}`"
                                 v-model="customer.password_confirmation">
                          <p class="error">{{ errors[0] }}</p>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="customer.country_id">
              <div class="col-sm-12">
                <div class="publicAdmin">
                  <div class="boxTitle">
                    <h2>{{ trans('your_communication_preferences') }}</h2>
                  </div>
                  <div class="boxBody">
                    <p v-html="trans(translations[type].email_opt_in_text_1)">

                    </p>
                    <div class="checkbox-container">
                      <label class="reg-check-label" for="marketing">
                        <input type="checkbox" id="marketing" v-model="customer.marketing">
                        <span v-html="trans(translations[type].wella_email_optin)"></span>
                      </label>
                    </div>

                    <hr class="regFormLine">
                    <p v-html="trans(translations[type].register_terms_explain, {link: replaceLink(type)})"></p>
                    <validation-provider :name="trans('error_field.terms')"
                                         :rules="{required: true, is_not: 0}"
                                         v-slot="{ errors }">
                      <div class="checkbox-container">

                        <label class="reg-check-label" for="accepted_terms">
                          <input type="checkbox" id="accepted_terms" :value="1"
                                 v-model="customer.terms">{{ trans(translations[type].register_terms_accepted) }} *
                        </label>
                      </div>
                      <br><br>
                      <p class="error" v-if="errors[0]">{{ trans('please_accept_terms') }}</p>
                    </validation-provider>
                    <hr class="regFormLine">
                    <p v-html="trans(translations[type].register_privacy_explain)"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="locale === 'en-GB'">
              <div class="col-sm-12">
                <div class="publicAdmin">
                  <div class="boxTitle">
                    <h2>{{ trans('register_enjoy') }}</h2>
                  </div>
                  <div class="boxBody">
                    <div class="order-perks">
                      <div class="row">
                        <div class="order-perk">
                          <div class="icon">
                            <img src="/images/woo-sprite-clock.png" alt="24/7 Ordering">
                          </div>
                          <div class="order-text">
                            {{ trans('register_enjoy_1') }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="order-perk">
                          <div class="icon">
                            <img src="/images/woo-sprite-cal.png" alt="Same day dispatch">
                          </div>
                          <div class="order-text">
                            {{ trans('register_enjoy_2') }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="order-perk">
                          <div class="icon">
                            <img src="/images/woo-sprite-deal.png"
                                 alt="Exclusive promotions">
                          </div>
                          <div class="order-text">
                            {{ trans('register_enjoy_3') }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <p class="order-label">{{ trans('register_enjoy_4') }}</p>
                    <div class="order-checkbox-container">
                      <label class="reg-check-label" for="ordering" id="ordering">
                        <input type="checkbox" v-model="customer.store">{{ trans('wella_order_online_signup') }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="publicAdmin">
                  <div class="boxTitle">
                    <h2>{{ trans('check_details_continue') }}</h2>
                  </div>
                  <div class="boxBody">
                    <p>{{ trans('register_check_1') }}</p>
                    <p>{{ trans('register_check_2') }}</p>
                    <div class="buttonWrapper">
                      <button class="btnNutural" type="button" @click.prevent="submit">
                        {{ trans('continue_with_registration') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </section>
    </div>
  </div>
</template>

<script>
import Alerts from "../Alerts";
import collect from 'collect.js';


export default {
  name: 'register',
  components: {
    Alerts,
  },
  props: {
    locale: {
      required: true,
      type: String,
    }
  },
  mounted() {
    axios.get('https://wsso.designlab.agency/api/countries').then(response => {
      this.countries = JSON.parse(response.data);
      this.countries = this.countries.filter(function(country) { return country.name != "Russia"; }); 
    });
    this.customer.locale = this.locale;
    this.setValidationTranslations(this.locale);
  },
  data: () => {
    return {
      translations: {
        main: {
          email_opt_in_text_1: 'email_opt_in_text_1',
          wella_email_optin: 'wella_email_optin',
          register_terms_explain: 'register_terms_explain',
          register_terms_accepted: 'register_terms_accepted',
          register_privacy_explain: 'register_privacy_explain',
        },
        coty: {
          email_opt_in_text_1: 'coty_email_opt_in_text_1',
          wella_email_optin: 'coty_wella_email_optin',
          register_terms_explain: 'coty_register_terms_explain',
          register_terms_accepted: 'coty_register_terms_accepted',
          register_privacy_explain: 'coty_register_privacy_explain',
        }
      },
      type: 'main',
      showSAPInfo: false,
      alerts: null,
      customer: {
        first_name: null,
        last_name: null,
        country_id: null,
        ship_to: null,
        email: null,
        email_confirmation: null,
        password: null,
        password_confirmation: null,
        marketing: 0,
        store: 0,
        terms: 0,
        locale: null,
      },
      countries: null,
    }
  },
  watch: {
    'customer.country_id': function () {
      if ([34, 15, 179].includes(this.customer.country_id)) {
        this.type = 'coty';
      } else {
        this.type = 'main';
      }
    },
  },
  methods: {
    replaceLink(type) {
      if (type === 'coty') {
        return 'https://www.coty.com/terms-use';
      }
      return 'https://www.wellacompany.com/terms-and-conditions';
    },
    trans(key, replacements = {}) {
      return this.$lang.trans(`general.${key}`, replacements);
    },
    countrytrans(key, replacements = {}) {
      return this.$lang.trans(`countries.${key}`, replacements);
    },
    hasSap() {
      const item = collect(this.countries).where('id', this.customer.country_id).first();
      return item && item.slug === 'united-kingdom';
    },
    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.alerts = {
            errors: [this.trans('please_review_errors')],
          };
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0;
        } else {
          axios.post('/sso/register', window.makeFormData(this.customer)).then(response => {
            if (response.data.success) {
              this.alerts = {
                success: response.data.success,
              };
              window.location.href = `https://${window.location.hostname}/${this.locale}/register/complete`;
            }
            if (response.data.errors) {
              this.alerts = {
                errors: response.data.errors,
              };
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0;
            }
          });
        }
      });
    },
    makeFormData(object) {
      let form = new FormData;
      for (let key in object) {
        if (object.hasOwnProperty(key)) {
          form.append(key, object[key]);
        }
      }
      return form;
    },
    setValidationTranslations(locale) {
      let lang;
      lang = locale;
      if (locale === 'no') {
        lang = 'nn_No';
      }
      if (locale === 'pt') {
        lang = 'pt_PT';
      }
      if (locale === 'us' || locale === 'en-GB') {
        lang = 'en';
      }
      this.vlocale = lang;
    }
  },
}
</script>
