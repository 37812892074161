<template>
    <button v-if="!hideBtn" v-on:click="confirmDelete" class="btn btn-danger inline">Delete</button>
</template>

<script>
    export default {
        name: "delete-asset",
        props: {
            current_locale: {
                required: true,
                type: String
            },
            asset: {
                requred: true,
                type: Number
            },
            hideBtn: {
                type: Boolean,
                required: true
            },
            remount: {
                type: Number,
                required: false,
            }
        },
        data: () => {
            return {}
        },
        mounted() {
            window.setDeleteListFalse();
            let currentObj = this;
            var loadAssets = setInterval(function () {
                if (!currentObj.isEmpty(window.deleteList)) {
                    currentObj.confirmDelete();
                    clearInterval(loadAssets);
                }
            }, 1000);
        },
        methods: {
            isEmpty(input) {
                return window.isEmpty(input);
            },
            doRemount() {
                window.deleteList = [];
                this.$emit('deleteassetremount', this.remount + 1);
            },
            confirmDelete(e = null) {
                if (e) {
                    e.preventDefault();
                }
                this.$swal({
                    title: 'Are you sure you want to delete this?',
                    text: 'Continuing will delete this asset permanently',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    customClass: window.SWAL_CLASSLIST,
                }).then((result) => {
                    if (result.value) {
                        this.deleteAsset();
                    } else {
                        this.doRemount();
                    }
                });
            },
            async deleteAsset() {
                if (!this.isEmpty(window.deleteList)) {
                    Swal.fire({
                        title: 'Deleting Assets...',
                        customClass: window.SWAL_CLASSLIST,
                        allowOutsideClick: false,
                        onBeforeOpen: () => {
                            Swal.showLoading();
                        }
                    });
                    for (const key in window.deleteList) {
                        if (window.deleteList.hasOwnProperty(key)) {
                            axios.post('/' + window.current_locale + '/remove-asset-resources', {
                                asset: window.deleteList[key],
                                locale: this.current_locale
                            }).then(response => {
                                this.progress = 50;
                            });
                            await axios.post('/' + window.current_locale + '/delete-asset', {
                                asset: window.deleteList[key],
                                locale: this.current_locale
                            }).then(response => {
                                this.progress = 100;
                            });
                        }
                    }
                    if (window.deleteList.length > 15) {
                        return window.location.reload();
                    }
                    await window.hasBeenDeleted().then(() => {
                        this.doRemount();
                    });
                } else if (this.asset) {
                    axios.post('/' + window.current_locale + '/remove-asset-resources', {
                        asset: this.asset,
                        locale: this.current_locale
                    }).then(response => {
                        this.progress = 50;
                    });
                    axios.post('/' + window.current_locale + '/delete-asset', {
                        asset: this.asset,
                        locale: this.current_locale
                    }).then(response => {
                        this.progress = 100;
                        Swal.fire({
                            title: 'Asset Successfully Deleted',
                            customClass: window.SWAL_CLASSLIST,
                        }).then(() => {
                            window.location.href = document.referrer;
                        });
                    }).catch(error => {
                        Swal.fire({
                            title: 'Unexpected Error',
                            icon: 'error',
                            text: error,
                            customClass: window.SWAL_CLASSLIST,
                        });
                    });
                }
            }
        }
    }
</script>