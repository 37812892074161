<template>
  <div>
    <div class="container-fluid maintenance">
      <br>
      <div class="row">
        <div class="col-sm-4 col-sm-offset-4">
          <img src="/assets/images/common/logo_new.png" alt="Wella" class="img-responsive">
        </div>
      </div>
      <br><br>
      <div class="maintenance-content">
        <div class="maintenance-block image-block">
        </div>
        <div class="maintenance-block red-back">
          <div class="explore-container">
            <h2>WHY NOT EXPLORE</h2>
            <hr>
            <div class="explore-links">
                <a href="https://uk.wellastore.com/" class="divided">Wella Store</a>
                <a href="https://education.wella.com/home" class="divided">Wella Education</a>
                <a href="https://www.wella.com/selector">Wella.com</a>
            </div>
          </div>
        </div>
      </div>
      <div class="footlinks">
        <br><br>
        <h2>OR FOLLOW US ON </h2>
        <br><br>
        <ul>
          <li><a href="http://www.wella.com/professional/countryselector" target="_blank"><img src="/assets/images/common/footer/logos-wella.png" alt="Wella Professionals"></a>
            <ul>
              <li><a href="https://www.instagram.com/WellaHairuki" target="_blank"><img class="social" src="/assets/images/common/footer/social-instagram.png" alt="Instagram"></a></li>
              <li><a href="https://www.youtube.com/channel/UCNVO80OXDb-a8EkiRnfNSUg?sub_confirmation=1" target="_blank"><img class="social" src="/assets/images/common/footer/social-youtube.png" alt="You Tube"></a></li>
              <li><a href="https://www.facebook.com/wellaprofessionals" target="_blank"><img class="social" src="/assets/images/common/footer/social-facebook.png" alt="Facebook"></a></li>
              <li><a href="https://twitter.com/WellaPro" target="_blank"><img class="social" src="/assets/images/common/footer/social-twitter.png" alt="Twitter"></a></li>
            </ul>
          </li>

          <li><a href="http://www.nioxin.com/en-EN" target="_blank"><img src="/assets/images/common/footer/logos-nioxin.png" alt="Nioxin"></a>
            <ul>
              <li><a href="https://www.youtube.com/user/discovernioxin" target="_blank"><img class="social" src="/assets/images/common/footer/social-youtube.png" alt="You Tube"></a></li>
              <li><a href="https://www.facebook.com/NioxinGlobal" target="_blank"><img class="social" src="/assets/images/common/footer/social-facebook.png" alt="Facebook"></a></li>
            </ul>
          </li>

          <li><a href="http://www.sebastianprofessional.com/en-EN/" target="_blank"><img src="/assets/images/common/footer/logos-sebastian.png" alt="Sebastian Professional"></a>
            <ul>
              <li><a href="https://www.instagram.com/sebastianpro_official/" target="_blank"><img class="social" src="/assets/images/common/footer/social-instagram.png" alt="Instagram"></a></li>
              <li><a href="https://www.youtube.com/user/OfficialSebastianPro" target="_blank"><img class="social" src="/assets/images/common/footer/social-youtube.png" alt="You Tube"></a></li>
              <li><a href="https://www.facebook.com/SebastianProf/?brand_redir=276981219075454" target="_blank"><img class="social" src="/assets/images/common/footer/social-facebook.png" alt="Facebook"></a></li>
              <li><a href="https://twitter.com/sebastianpro" target="_blank"><img class="social" src="/assets/images/common/footer/social-twitter.png" alt="Twitter"></a></li>
            </ul>
          </li>

          <li><a href="http://www.systemprofessional.com/en-GB/" target="_blank"><img src="/assets/images/common/footer/logos-sp.png" alt="System Professional"></a>
            <ul>
              <li><a href="https://www.instagram.com/systemprofessional/" target="_blank"><img class="social" src="/assets/images/common/footer/social-instagram.png" alt="Instagram"></a></li>
              <li><a href="#/" target="_blank"><img class="social" src="/assets/images/common/footer/social-youtube.png" alt="You Tube"></a></li>
              <li><a href="https://www.facebook.com/SPSystemProfessional" target="_blank"><img class="social" src="/assets/images/common/footer/social-facebook.png" alt="Facebook"></a></li>
            </ul>
          </li>

          <li><a href="http://www.sassoon.com/" target="_blank"><img src="/assets/images/common/footer/logos-sassoon.png" alt="Sassoon Professional"></a>
            <ul>
              <li><a href="https://www.instagram.com/sassoonacademyofficial/" target="_blank"><img class="social" src="/assets/images/common/footer/social-instagram.png" alt="Instagram"></a></li>
              <li><a href="https://www.youtube.com//channel/UCQJ2EWtBr45Hn16zz01HcRw" target="_blank"><img class="social" src="/assets/images/common/footer/social-youtube.png" alt="You Tube"></a></li>
              <li><a href="https://www.facebook.com/SassoonAcademy/" target="_blank"><img class="social" src="/assets/images/common/footer/social-facebook.png" alt="Facebook"></a></li>
            </ul>
          </li>

          <li><a href="http://www.international.clairolprofessional.com/countryselector" target="_blank"><img src="/assets/images/common/footer/logos-clairol.png" alt="Clairol Professional"></a>
            <ul>
              <li><a href="https://www.youtube.com/user/ClairolProf" target="_blank"><img class="social" src="/assets/images/common/footer/social-youtube.png" alt="You Tube"></a></li>
              <li><a href="https://www.facebook.com/ClairolProfessional" target="_blank"><img class="social" src="/assets/images/common/footer/social-facebook.png" alt="Facebook"></a></li>
              <li><a href="https://www.pinterest.com/ClairolProf/" target="_blank"><img class="social" src="/assets/images/common/footer/social-pinterest.png" alt="Pinterest"></a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "maintenance",
  }
</script>
