
let bulletsOne = [__('tours.initial.bullet1'), __('tours.initial.bullet2'), __('tours.initial.bullet3')];
let bulletsTwo = [__('tours.initial.bullet4'), __('tours.initial.bullet5'), __('tours.initial.bullet6')];

let initialHtml = '<p>' + __('tours.initial.text') + '</p>';
initialHtml += '<div class="startBulletCont"> <ul>';
for (let bullet in bulletsOne) {
    initialHtml += '<li>' + bulletsOne[bullet] + '</li>';
}
initialHtml += '</ul>';
initialHtml += '<ul>';
for (let bullet2 in bulletsTwo) {
    initialHtml += '<li>' + bulletsTwo[bullet2] + '</li>';
}
initialHtml += '</ul> </div>';
initialHtml += '<img class="tourImage" src=" ' + __('tours.initial.img') + '"/>';

let steps = [
    { //0
        element: '#step0',
        intro: '<h2>' + __('tours.initial.heading') + '</h2>' + initialHtml,
        tooltipClass: 'startingPopup',
        // position: 'bottom',
    },
    { //1
        element: '#mobMenuTrigger',
        intro: '<h2>' + __('tours.tour.steps.step0.mobNavTrig') + '</h2>',
        // position: 'bottom',
    },
    { //2
        element: '#step1',
        intro: '<h2>' + __('tours.tour.steps.step1.title') + '</h2><p>' + __('tours.tour.steps.step1.para1') + '</p>',
        // position: 'bottom',
        tooltipClass: 'tourDtopNav',
    },
    { //3
        element: '#step2',
        intro: '<h2>' + __('tours.tour.steps.step2.title') + '</h2><p>' + __('tours.tour.steps.step2.para1') + '</p><img class="tourImage" src="' + __('tours.tour.steps.step2.img') + '" alt"" >',
        position: 'right',
    },
    { //4
        element: '.asset-result',
        intro: '<h2>' + __('tours.tour.steps.step3.title') + '</h2>' + '<ol>' + '<li>' + __('tours.tour.steps.step3.bullet1') + '</li>' + '<li>' + __('tours.tour.steps.step3.bullet2') + '</li>' + '<li>' + __('tours.tour.steps.step3.bullet3') + '</li>' + '</ol>' + '<img class="tourImage" src="' + __('tours.tour.steps.step3.img') + '" alt"" >',
        position: 'left',
        tooltipClass: 'tourDLNoLogin',
    },
    { //5
        element: '#firstTwoAssets',
        intro: '<h2>' + __('tours.tour.steps.step4.title') + '</h2>' + '<ol>' + '<li>' + __('tours.tour.steps.step4.bullet1') + '</li>' + '<li>' + __('tours.tour.steps.step4.bullet2') + '</li>' + '</ol>' + __('tours.tour.steps.step4.video') + '<p>' + __('tours.tour.steps.step4.para1') + ' <a href="/register/form">' + __('tours.tour.steps.step4.a') + '</a></p>',
        position: 'left',
        tooltipClass: 'tourMulitDLLogin',
    },
    { //6
        element: '#firstTwoAssets',
        intro: '<h2>' + __('tours.tour.steps.step4-5.title') + '</h2><p>' + __('tours.tour.steps.step4-5.para1') + '</p>' + '<ol>' + '<li>' + __('tours.tour.steps.step4-5.bullet1') + '</li>' + '<li>' + __('tours.tour.steps.step4-5.bullet2') + '</li>' + '<li>' + __('tours.tour.steps.step4-5.bullet3') + '</li>' + '<li>' + __('tours.tour.steps.step4-5.bullet4') + '</li>' + '</ol>' + __('tours.tour.steps.step4-5.video'),
        position: 'floating',
        tooltipClass: 'tourEditPDF',
    },
    { //7
        element: '#firstTwoAssets',
        intro: '<h2>' + __('tours.tour.steps.step4-5-5.title') + '</h2>' + '<p>' + __('tours.tour.steps.step4-5-5.para1') + '</p>' + __('tours.tour.steps.step4-5-5.video') + '<p>' + __('tours.tour.steps.step4-5-5.para2') + '</p>',
        position: 'floating',
        tooltipClass: 'tourDownload',
    },
    { //8
        element: '.zEWidget-launcher',
        intro: '<h2>' + __('tours.tour.steps.step5.title') + '</h2><p>' + __('tours.tour.steps.step5.content') + '</p>' + '<img class="tourImage" src="' + __('tours.tour.steps.step5.img') + '" alt"" >',
        position: 'top',
        tooltipClass: 'supportTipOverride',
    },
    { //9
        element: '#step6',
        intro: '<h2>' + __('tours.tour.steps.step6.title') + '</h2><p></p>',
        position: 'bottom',
    },
];

if (window.current_locale === 'ru') {
    steps.splice(8, 1);
}
if (!(window.innerWidth < 1000)) {
    steps.splice(1, 1);
    steps = steps.filter(function (el) {
        return el != null;
    });
} else {
    steps.splice(2, 1);
    steps = steps.filter(function (el) {
        return el != null;
    });
}


export {steps};